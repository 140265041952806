export default {
  data () {
    return {
      mostvisible: {},
      currentSection: null
    }
  },

  watch: {
    mostvisible: {
      deep: true,
      handler (val) {
        let max = {
          val: 0,
          sec: null
        }

        // Loop through mostvisible and check for highest value
        Object.entries(val).forEach(sec => {
          let section = sec[1]
          if (section.val > max.val) {
            max = {
              val: section.val,
              sec: section.sec
            }
          }
        })

        this.currentSection = max.sec
      }
    }
  }
}
