import VueScrollTo from 'vue-scrollto'
import Vue from 'vue'
import { scroller } from 'vue-scrollto/src/scrollTo'
Vue.use(VueScrollTo)

export default {
  computed: {
    contextMenu () {
      return this.$store.state.docMen || this.$mq === 'sm'
    },

    archives () {
      return this.$store.getters.archives
    },

    documents () {
      return this.$store.getters.documents
    },

    bodyEl () {
      return this.$store.state.bodyEl
    },

    option () {
      return this.$route.params.option === 'documents'
    },

    toIndex () {
      return this.$store.state.toIndex
    },

    fromIndex () {
      return this.$store.state.fromIndex
    }
  },

  methods: {
    mobileMenScroll (sec) {
      let div = document.getElementById('men' + sec)
      let options2 = {
        container: '#exhibitionMenu',
        offset: (window.innerWidth / 2 - div.offsetWidth / 2) * -1,
        easing: 'ease',
        force: true,
        cancelable: false,
        x: true,
        y: false
      }

      const scrollHorz = scroller()
      scrollHorz(div, 500, options2)
    },

    goTo (sec) {
      this.$store.commit('editState', { state: 'mobAutoMenu', data: true })
      this.$store.commit('editState', { state: 'stopRequest', data: true })
      this.$store.commit('editState', { state: 'scrollMenu', data: false })

      let options = {
        container: '#app',
        easing: 'ease',
        force: true,
        offset: (window.outerWidth / 100 * 3) * -1,
        cancelable: true,
        onDone: () => {
          this.$store.commit('editState', { state: 'mobAutoMenu', data: false })
          this.$store.commit('editState', { state: 'stopRequest', data: false })
        },
        x: false,
        y: true
      }

      let div = document.getElementById(sec)
      const scrollVert = scroller()
      scrollVert(div, 500, options)

      // Mobile menu scrolling
      if (this.$mq === 'sm') {
        this.mobileMenScroll(sec)
      }
    }
  }
}
