export default {
  inserted: (el, binding, vnode) => {
    let handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) el.classList.add('hideBar')
        if (entry.isIntersecting) el.classList.remove('hideBar')
      })
    }

    function createObserver () {
      let remVal = vnode.context.$store.state.remVal * 2

      let offset = window.outerWidth / 100 * (remVal + 1)
      const options = {
        rootMargin: `-${offset}px 0px ${offset}px 0px`,
        threshold: Array.apply(null, { length: 100 }).map((n, i) => i / 100)
      }

      // Observe
      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(el)
    }

    if (window['IntersectionObserver']) {
      vnode.context.$nextTick(() => {
        createObserver()
      })
    }
  }
}
