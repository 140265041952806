import Vue from 'vue'

export default {
  inserted: (el, binding, vnode) => {
    // The component from which the directive is called
    const $this = vnode.context
    // The section name
    const section = binding.arg

    let handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        // Percentage of element visible
        let currentIn = Math.round(entry.intersectionRatio * 100)
        // Match the element in the mostvisible array
        let a = $this.mostvisible[section]
        // Set new value
        a.val = currentIn
      })
    }

    function createObserver () {
      const options = {
        rootMargin: `0px 0px 0px 0px`,
        threshold: Array.apply(null, { length: 100 }).map((n, i) => i / 100)
      }

      // Object
      let thisVis = {
        sec: section,
        val: 0
      }

      // Add object to mostvisible array
      Vue.set($this.mostvisible, section, thisVis)
      // Observe
      const observer = new IntersectionObserver(handleIntersect, options)
      observer.observe(el)
    }

    if (window['IntersectionObserver']) {
      $this.$nextTick(() => {
        createObserver()
      })
    }
  }
}
