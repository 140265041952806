<template>
  <div class="menuTop" ref="top">
    <div class="topItem menuTopButton">
      <div @click="setMenu">MENU <span class="closeButton">&#215;</span></div>
    </div>

    <div class="topItem">
      <div @click="goHome">I-DEA</div>
    </div>

    <div class="topItem">
      <div @click="openAbout" v-if="$store.state.about">{{$store.state.about.title}}</div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'
import Vue from 'vue'
Vue.use(VueScrollTo)

export default {
  props: ['exhibitions'],

  methods: {
    goHome () {
      this.$router.push({ path: `/${this.$store.state.lan}` })
    },

    openAbout () {
      if (this.menuOpen) {
        // Close menu
        this.$store.commit('editState', { state: 'mainMenu', data: !this.menuOpen })
        this.$store.commit('editState', { state: 'hideContent', data: true })
        this.$router.push(`/${this.$store.state.lan}/about`)
        window.setTimeout(() => {
          // Show content
          this.$store.commit('editState', { state: 'hideContent', data: false })
        }, 1000)
      } else {
        if (this.$route.name === 'exhibition' && !this.menuOpen) {
          // Callback
          let options = {
            container: '#app',
            easing: 'ease',
            force: true,
            cancelable: true,
            x: false,
            y: true,
            onDone: () => {
              // When at top hide content and open menu
              this.$router.push(`/${this.$store.state.lan}/about`).catch(e => {})
            }
          }

          // Scroll menu to top of page, check callback
          let div = document.getElementById('top')
          let todiv = document.getElementById('exhibitionMenus')
          if (this.bodyEl.scrollTop !== div.offsetHeight) {
            this.$scrollTo(todiv, 500, options)
          } else {
            this.$router.push(`/${this.$store.state.lan}/about`).catch(e => {})
          }
        } else {
          this.$router.push(`/${this.$store.state.lan}/about`).catch(e => {})
        }
      }
    },

    callback () {
      this.$store.commit('editState', { state: 'hideContent', data: true })
      window.setTimeout(() => {
        this.$store.commit('editState', { state: 'mainMenu', data: !this.menuOpen })
      }, 300)
    },

    setMenu () {
      // Change transition animation name to prevent animated menu
      this.$store.commit('editState', { state: 'menuAnimation', data: 'menu' })

      if (this.$route.meta.noMenuScr && !this.menuOpen) {
        this.callback()

      // If exhibition page and menu closed
      } else if (this.$route.name === 'exhibition' && !this.menuOpen) {
        // Callback
        let options = {
          container: '#app',
          easing: 'ease',
          force: true,
          cancelable: true,
          x: false,
          y: true,
          onDone: () => {
            // When at top hide content and open menu
            this.callback()
          }
        }

        // Scroll menu to top of page, check callback
        let div = document.getElementById('top')
        let todiv = document.getElementById('exhibitionMenus')
        if (this.bodyEl.scrollTop !== div.offsetHeight) {
          this.$scrollTo(todiv, 500, options)
        } else {
          this.callback()
        }

      // If exhibition page and menu open
      } else if (this.$route.name !== 'home' && this.menuOpen) {
        // Close menu
        this.$store.commit('editState', { state: 'mainMenu', data: !this.menuOpen })
        window.setTimeout(() => {
          // Show content
          this.$store.commit('editState', { state: 'hideContent', data: false })
        }, 1000)

      // If not exhibition page open/close menu
      } else {
        this.$store.commit('editState', { state: 'mainMenu', data: !this.menuOpen })
      }
    }
  },

  computed: {
    bodyEl () {
      return this.$store.state.bodyEl
    },

    menuOpen () {
      return this.$store.state.mainMenu
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.menuTop {
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
}

.closeButton {
  color: black;
  margin-left: 0.5rem;
}

.menuOpen .menuTopButton {
  color: grey;

  .closeButton {
    color: black;
  }
}

.topItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0.3rem;

  div {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
}

.topItem:nth-child(1) {
  width: 20%;
  justify-content: flex-start;

  div {
    cursor: pointer;
  }
}

.topItem:nth-child(3) {
  justify-content: flex-end;
  width: 20%;
}

.topItem:nth-child(2) {
  flex: 1;
}

.closeButton {
  display: none;
  margin-right: 0.5rem;
}

.menuOpen .closeButton {
  display: block;
}
</style>
