<template>
  <div id="singleDocument" class="singleDoc" v-if="document">
    <div class="sectionOuter" v-current:doin>
      <div id="doin"></div>
      <div class="section">
        <div class="image fadeOut" v-if="document.image.src_low" :key="document.image.src_low">
          <div class="imageInner">
            <img :src="document.image.src_low">
            <div class="caption" v-if="document.image.desc" v-html="document.image.desc"></div>
          </div>
        </div>

        <div class="video fadeOut" v-else-if="document.video" :key="document.video.url">
          <div class="imageInner">
            <div class="playHover" @click="playVideo" @mousemove="hidePause" :class="{playing: playing, pause: pause}">
              <div class="playButton"></div>
              <div class="pauseButton">
                <div></div>
                <div></div>
              </div>
            </div>

            <video playsinline loop ref="video">
              <source :src="document.video.url" type="video/mp4">
            </video>
            <div class="caption" v-if="document.video.desc" v-html="document.video.desc"></div>
          </div>
        </div>

        <div class="audio" v-else-if="document.audio" :key="document.audio.url">
          <div class="playHover" @click="playVideo" @mousemove="hidePause" :class="{playing: playing, pause: pause}">
            <div class="playButton"></div>
            <div class="pauseButton">
              <div></div>
              <div></div>
            </div>
          </div>

          <div class="imageInner">
            <audio controls ref="video">
              <source :src="document.audio.url">
            </audio>
          </div>
        </div>

        <Subtitle :content="document.title" />
      </div>
    </div>

    <div id="dets"></div>
    <div class="sectionOuter" v-current:dets>
      <div class="section">
        <div v-if="document.author.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.author.title"></div>
            <div class="atRight fadeOut" v-html="document.author.text"></div>
          </div>
        </div>

        <div v-if="document.archive.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.archive.title"></div>
            <div class="atRight fadeOut" v-html="document.archive.text"></div>
          </div>
        </div>

        <div v-if="document.featured.text.length" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.featured.title"></div>
            <div class="atRight fadeOut">
              <span v-for="(expo, index) in document.featured.text" :key="index + 'expo'" v-html="expo"></span>
            </div>
          </div>
        </div>

        <div v-if="document.location.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.location.title"></div>
            <div class="atRight fadeOut" v-html="document.location.text"></div>
          </div>
        </div>

        <div v-if="document.topic.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.topic.title"></div>
            <div class="atRight fadeOut" v-html="document.topic.text"></div>
          </div>
        </div>

        <div v-if="document.media.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.media.title"></div>
            <div class="atRight fadeOut" v-html="document.media.text"></div>
          </div>
        </div>

        <div v-if="document.year.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.year.title"></div>
            <div class="atRight case fadeOut" v-html="document.year.text"></div>
          </div>
        </div>

        <div v-if="document.constitutes.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="document.constitutes.title"></div>
            <div class="atRight fadeOut" v-html="document.constitutes.text"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Subtitle from '@/components/Subtitle.vue'
import Footer from '@/components/Footer.vue'
import ScrollDirective from '@/mixins/DirectiveScroll'
import ScrollMixin from '@/mixins/MixinScroll'
import Vue from 'vue'

Vue.directive('current', ScrollDirective)

export default {
  data () {
    return {
      playing: false,
      pause: false,
      timer: false
    }
  },

  mixins: [ScrollMixin],

  components: {
    Subtitle,
    Footer
  },

  computed: {
    documents () {
      return this.$store.getters.documents
    },

    document (val) {
      if (this.documents) {
        return this.documents.find(page => page.slug === this.$route.params.doc)
      } else {
        return null
      }
    }
  },

  methods: {
    playVideo () {
      let video = this.$refs.video
      if (this.playing) {
        this.playing = false
        video.pause()
      } else {
        this.playing = true
        video.play()
      }
    },

    hidePause () {
      if (this.timer) clearTimeout(this.timer)

      this.pause = false
      this.timer = window.setTimeout(() => {
        this.pause = true
      }, 1000)
    }
  },

  watch: {
    document: {
      immediate: true,
      handler (val) {
        if (val) this.$store.commit('editState', { state: 'docTitle', data: this.document.title })
      }
    },

    currentSection (val) {
      // Commit current section to store
      this.$store.commit('editState', { state: 'docSection', data: val })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.playHover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.playButton {
  opacity: 1;
  width: 0;
  height: 0;
  border-top: 7rem solid transparent;
  border-bottom: 7rem solid transparent;
  border-left: calc(7rem * 2) solid white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  transition: opacity 0.2s ease;
}

.pauseButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 14rem;
  width: 10rem;
  transition: opacity 0.2s ease;
  opacity: 0;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  display: flex;

  div {
    width: 3.5rem;
    height: 100%;
    background-color: white;
  }

  div:last-child {
    margin-left: auto;
  }
}

.playHover.playing {
  .playButton {
    opacity: 0;
  }

  &:hover {
    .pauseButton {
      opacity: 1;
    }

  &:hover.pause {
    .pauseButton {
      opacity: 0;
    }
  }
  }
}

.audio {
  .imageInner {
    height: 0;
    visibility: hidden;
  }

  .playHover {
    top: 2.5rem;
  }
}

@media screen and (max-width: $breakPoint1) {
  .playButton {
    border-top: 4rem solid transparent;
    border-bottom: 4rem solid transparent;
    border-left: calc(4rem * 2) solid white;
  }

  .pauseButton {
    height: 8rem;
    width: 6rem;

    div {
      width: 2rem;
    }
  }
}
</style>
