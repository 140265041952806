<template>
  <div id="floatingMenu">
    <MenuTop />
  </div>
</template>

<script>
import MenuTop from '@/components/menu/MenuTop.vue'

export default {
  props: ['background'],

  components: {
    MenuTop
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#floatingMenu {
  position: relative;
  height: 4rem;
  font-size: $fonthorz;
  font-family: $horizontal;
  line-height: $horizontalLeading;
  z-index: 11;
  padding: 0 $padding;
}

#floatingMenu:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: $padding;
  right: $padding;
  border-bottom: $border;
}

.left {
  flex: 1;
  text-align: left;
}

.center {
  width: 50%;
  text-align: center;
}

.right {
  flex: 1;
  text-align: right;
}
</style>
