<template>
  <transition-group :name="$store.state.transitionName" v-if="$route.meta.group === 'index'" class="pagefade">
    <!-- Single Document -->
    <div id="exhibitionMenu" class="exhibitionMenu singleDoc" v-if="toIndex === 'index-doc' || toIndex === 'index-arc-doc'" key="indexDoc">
      <div class="docBack fadeOut" id="mendoin">
        <template v-if="$route.params.archive">
          <span @click="$router.push(`/${$store.state.lan}/index/archives/archive/${$route.params.archive}`)">&larr;
            <transition name="docMenu">
              <span v-show="contextMenu">{{$store.state.translations.archive[$store.state.lan]}}</span>
            </transition>
          </span>
        </template>

        <template v-else>
          <span @click="$router.push(`/${$store.state.lan}/index/documents`)">&larr;
            <transition name="docMenu">
              <span v-show="contextMenu">{{$store.state.translations.documents[$store.state.lan]}}</span>
            </transition>
          </span>
        </template>
      </div>

      <transition-group name="docMenu">
        <div key="title" class="docTitle" v-if="!$store.state.docMen">
          <div class="fadeOut" v-html="$store.state.docTitle"></div>
        </div>

        <div key="others" class="othersMenu" v-if="contextMenu">
          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'doin'}" class="exMenInner" @click="goTo('doin')">{{$store.state.translations.introduction[$store.state.lan]}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'dets'}" class="exMenInner" @click="goTo('dets')" id="mendets">{{$store.state.translations.details[$store.state.lan]}}</div>
          </div>
        </div>
      </transition-group>
    </div>

    <!-- Single Archive -->
    <div id="exhibitionMenu" class="exhibitionMenu singleArc" v-if="toIndex === 'index-arc'" key="indexArc">
      <div class="docBack fadeOut" id="mendoin">
        <span @click="$router.push(`/${$store.state.lan}/index/archives`)">&larr;
          <transition name="docMenu">
            <span v-show="contextMenu">{{$store.state.archives.title}}</span>
          </transition>
        </span>
      </div>

      <transition-group name="docMenu">
        <div key="title" class="docTitle" v-if="!$store.state.docMen">
          <div class="fadeOut" v-html="$store.state.arcTitle"></div>
        </div>

        <div key="others" class="othersMenu" v-if="contextMenu">
          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'doin'}" class="exMenInner" @click="goTo('doin')">{{$store.state.translations.introduction[$store.state.lan]}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'dets'}" class="exMenInner" @click="goTo('dets')" id="mendets">{{$store.state.translations.details[$store.state.lan]}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'docs'}" class="exMenInner" @click="goTo('docs')" id="mendocs">{{$store.state.translations.selectedDocs[$store.state.lan]}}</div>
            <div class="textImSel" v-if="$store.state.arcSection === 'docs'">: &nbsp;
              <div :class="{iselected: $store.state.indexDocsText}" @click="$store.state.indexDocsText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.indexDocsText}" @click="$store.state.indexDocsText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <!-- Main View -->
    <div class="subMenu indexMain" v-if="toIndex === 'documents' || toIndex === 'archives'" key="indexMain">
      <div id="indexMen" :class="{docs: option, arcs: !option}">
        <div id="docsTextImage" class="fadeOut">
          <div class="textImSel">
            <div :class="{iselected: $store.state.indexDocsText}" @click="$store.state.indexDocsText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.indexDocsText}" @click="$store.state.indexDocsText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
          </div>
        </div>

        <div class="flex fadeOut">
          <div class="docs">
            <div class="inner"><router-link :to="`/${$store.state.lan}/index/documents`">{{$store.state.translations.documents[$store.state.lan]}} <span class="num" v-html="documents.length"></span></router-link></div>
          </div>
        </div>

        <div class="flex fadeOut">
          <div class="arcs">
            <div class="inner"><router-link :to="`/${$store.state.lan}/index/archives`">{{$store.state.archives.title}} <span class="num" v-html="archives.length"></span></router-link></div>
          </div>
        </div>

        <div class="docsMobOuter flex fadeOut">
          <div class="docsMob">
            <div class="inner"><router-link :to="`/${$store.state.lan}/index/documents`">{{$store.state.translations.documents[$store.state.lan]}} <span class="num" v-html="documents.length"></span></router-link></div>
          </div>
        </div>

        <div id="arcsTextImage" class="fadeOut">
          <div class="textImSel">
            <div :class="{iselected: $store.state.indexArcsText}" @click="$store.state.indexArcsText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.indexArcsText}" @click="$store.state.indexArcsText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import MixinExhibitionMenu from '@/mixins/MixinExhibitionMenu.js'

export default {
  mixins: [MixinExhibitionMenu]
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

</style>
