export default {
  data () {
    return {
      docsScr: 0,
      arcDocScr: 0,
      bodScr: 0,
      exHeight: 0,
      hideTop: false
    }
  },

  computed: {
    bodyEl () {
      return this.$store.state.bodyEl
    },

    scrollMenu () {
      return this.$store.state.scrollMenu
    },

    toIndex () {
      return this.$store.state.toIndex
    },

    fromIndex () {
      return this.$store.state.fromIndex
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.setToIndex(to)
    this.bodSrc = this.bodyEl.scrollTop

    if (from.meta.name === 'index-doc' || from.meta.name === 'index-arc' || from.meta.name === 'index-arc-doc') {
      this.$store.commit('editState', { state: 'fromIndex', data: from.meta.name })
    } else {
      this.$store.commit('editState', { state: 'fromIndex', data: 'documents' })
    }

    next()
  },

  beforeRouteLeave (to, from, next) {
    if (to.meta.group !== from.meta.group) {
      this.$store.commit('editState', { state: 'toIndex', data: to.meta.group })
    }

    next()
  },

  methods: {
    rem4 () {
      return this.scrollMenu ? 0 : Math.round(window.outerWidth / 100 * this.$store.state.remVal)
    },

    remOffset () {
      return this.scrollMenu ? Math.round(window.outerWidth / 100 * (this.$store.state.remVal / 2)) : 0
    },

    setToIndex (to) {
      if (to.meta.name === 'index-doc' || to.meta.name === 'index-arc' || to.meta.name === 'index-arc-doc') {
        this.$store.commit('editState', { state: 'toIndex', data: to.meta.name })
      } else {
        this.$store.commit('editState', { state: 'toIndex', data: 'documents' })
      }
    },

    enter () {
      console.log(this.$store.state.transitionName)
      if (this.$store.state.transitionName === 'index') {
        // Get height of top element
        let topH = document.getElementById('top').offsetHeight

        // Get menu height
        let rem4 = this.rem4()

        // Get offset height if menu is open
        let remOffset = this.remOffset()

        this.$nextTick(() => {
          // If not going to documents, hide top element
          if (this.toIndex === 'index-doc' || this.toIndex === 'index-arc' || this.toIndex === 'index-arc-doc') {
            this.hideTop = true
          }

          this.$nextTick(() => {
            // Stop request loop for show/hide menu
            this.$store.commit('editState', { state: 'stopRequest', data: true })

            // Set body scroll, with offset if menu is visible
            this.bodyEl.scrollTo(0, rem4)
            let dScr = this.bodSrc - rem4 - topH

            // If entering to documents from document
            if (this.toIndex === 'documents' && this.fromIndex === 'index-doc') {
              document.getElementById('singleDocument').style.top = `-${dScr}px`
              document.getElementById('sections').style.top = `-${this.docsScr}px`
            }

            // If entering to documents from archive
            if (this.toIndex === 'documents' && this.fromIndex === 'index-arc') {
              document.getElementById('singleArchive').style.top = `-${dScr}px`
              document.getElementById('sections').style.top = `-${this.docsScr}px`
            }

            // If entering to document from documents
            if (this.toIndex === 'index-doc' && this.fromIndex === 'documents') {
              this.$store.commit('editState', { state: 'docMen', data: false })
              this.docsScr = dScr
              document.getElementById('singleDocument').style.top = `-${remOffset}px`
              document.getElementById('sections').style.top = `-${dScr}px`
            }

            // If entering to archive from documents
            if (this.toIndex === 'index-arc' && this.fromIndex === 'documents') {
              this.$store.commit('editState', { state: 'docMen', data: false })
              this.docsScr = dScr
              document.getElementById('singleArchive').style.top = `-${remOffset}px`
              document.getElementById('sections').style.top = `-${dScr}px`
            }

            // If entering to archive from document
            if (this.toIndex === 'index-arc' && this.fromIndex === 'index-arc-doc') {
              if (this.arcDocScr > window.outerWidth / 100 * 10) this.$store.commit('editState', { state: 'docMen', data: true })
              document.getElementById('singleDocument').style.top = `-${dScr}px`
              document.getElementById('singleArchive').style.top = `-${this.arcDocScr}px`
            }

            // If entering to document from archive
            if (this.toIndex === 'index-arc-doc' && this.fromIndex === 'index-arc') {
              this.$store.commit('editState', { state: 'docMen', data: false })
              this.arcDocScr = dScr
              document.getElementById('singleDocument').style.top = `-${remOffset}px`
              document.getElementById('singleArchive').style.top = `-${dScr}px`
            }

            this.bodyEl.scrollTo(0, rem4 + 2)
            this.bodyEl.scrollTo(0, rem4)
          })
        })
      } else {
        this.hideTop = false
      }
    },

    afterEnter () {
      if (this.$store.state.transitionName === 'index') {
        let rem4 = this.rem4()
        let remOffset = this.remOffset()

        if (this.toIndex === 'documents') {
          this.hideTop = false

          this.$nextTick(() => {
            let topH = document.getElementById('top').offsetHeight
            this.bodyEl.scrollTo(0, this.docsScr + rem4 + topH)
            document.getElementById('sections').style.top = '0px'
            this.$nextTick(() => {
              this.bodyEl.scrollTo(0, this.docsScr + rem4 + topH + 2)
              this.bodyEl.scrollTo(0, this.docsScr + rem4 + topH)
            })
          })
        }

        if (this.toIndex === 'index-doc') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleDocument').style.top = '0px'
        }

        if (this.toIndex === 'index-arc-doc') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleDocument').style.top = '0px'
        }

        if (this.toIndex === 'index-arc' && this.fromIndex === 'archives') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleArchive').style.top = '0px'
        }

        if (this.toIndex === 'index-arc' && this.fromIndex === 'index-arc-doc') {
          this.bodyEl.scrollTo(0, this.arcDocScr + rem4)
          document.getElementById('singleArchive').style.top = '0px'
        }

        this.$nextTick(() => {
          this.$store.commit('editState', { state: 'stopRequest', data: false })
        })
      }
    }
  },

  mounted () {
    this.setToIndex(this.$route)

    // If not going to documents, hide top element
    if (this.toIndex === 'index-doc' || this.toIndex === 'index-arc' || this.toIndex === 'index-arc-doc') {
      this.hideTop = true
    } else {
      this.hideTop = false
    }
  }
}
