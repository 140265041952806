<template>
  <transition-group :name="$store.state.transitionName" v-if="$route.meta.group === 'about'" class="pagefade">
    <!-- Expo menu -->
    <div id="exhibitionMenu" class="exhibitionMenu indexMain" v-show="toIndex === 'documents'" key="indexMain">
      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.aboutSection === 'inro'}" class="exMenInner" @click="goTo('inro')" id="meninro">{{$store.state.about.project.title}}</div>
      </div>

      <div class="exMenOuter fadeOut" v-if="$store.state.about.news">
        <div :class="{selected: $store.state.aboutSection === 'news'}" class="exMenInner" @click="goTo('news')" id="mennews">{{$store.state.about.news.title}}</div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.aboutSection === 'inst'}" class="exMenInner" @click="goTo('inst')" id="meninst">{{$store.state.about.venue.title}}</div>
      </div>

      <div class="exMenOuter fadeOut" v-if="$store.state.about.collateral">
        <div :class="{selected: $store.state.aboutSection === 'coll'}" class="exMenInner" @click="goTo('coll')" id="mencoll">Collateral</div>
        <div class="textImSel" v-if="$store.state.aboutSection === 'coll'">: &nbsp;
          <div :class="{iselected: $store.state.collateralText}" @click="$store.state.collateralText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.collateralText}" @click="$store.state.collateralText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
        </div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.aboutSection === 'team'}" class="exMenInner" @click="goTo('team')" id="menteam">{{$store.state.about.team.title}}</div>
      </div>

      <div class="exMenOuter fadeOut" v-if="$store.state.about.partners">
        <div :class="{selected: $store.state.aboutSection === 'part'}" class="exMenInner" @click="goTo('part')" id="menpart">{{$store.state.about.partners.title}}</div>
      </div>
    </div>

    <!-- Document menu -->
    <div id="exhibitionMenu" class="exhibitionMenu singleDoc" v-if="toIndex === 'index-doc' || toIndex === 'index-arc-doc'" key="indexDoc">
      <div class="docBack fadeOut" id="mendoin">
        <span @click="$router.push(`/${$store.state.lan}/about`)">&larr;
          <transition name="docMenu">
            <span v-show="contextMenu">About</span>
          </transition>
        </span>
      </div>

      <transition-group name="docMenu">
        <div key="title" class="docTitle" v-if="!$store.state.docMen">
          <div class="fadeOut" v-html="$store.state.docTitle"></div>
        </div>

        <div key="others" class="othersMenu" v-if="contextMenu">
          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'doin'}" class="exMenInner" @click="goTo('doin')" id="mendoin">Introduction</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'dets'}" class="exMenInner" @click="goTo('dets')" id="mendets">Scenario</div>
          </div>
        </div>
      </transition-group>
    </div>
  </transition-group>
</template>

<script>
import MixinExhibitionMenu from '@/mixins/MixinExhibitionMenu.js'

export default {
  mixins: [MixinExhibitionMenu],

  computed: {
    archives () {
      return this.$store.getters.archives
    },

    about () {
      return this.$store.getters.about
    },

    docView () {
      return this.exhibition.doc_text_view
    },

    arcView () {
      return this.exhibition.arc_text_view
    }
  }
}
</script>
