<template>
  <div id="app" ref="body" :class="['group-' + $route.meta.group, $route.name, {hideContent: $store.state.hideContent, menuOpen: mainMenu, scrollMenu: scrollMenu, docMen: $store.state.docMen}]">
    <transition :name="$store.state.menuAnimation">
      <Menu v-show="mainMenu" />
    </transition>

    <transition :name="$store.state.transitionMain" mode="out-in" @after-leave="enter">
      <router-view class="transitionMain" :key="$store.state.pageKey" />
    </transition>
  </div>
</template>

<script>
import MixinExhibitionMenu from '@/mixins/MixinExhibitionMenu.js'
import Menu from '@/components/menu/Menu.vue'

export default {
  data () {
    return {
      dir: null,
      lastS: 0
    }
  },

  components: {
    Menu
  },

  mixins: [MixinExhibitionMenu],

  computed: {
    mainMenu () {
      return this.$store.state.mainMenu
    },

    bodyEl () {
      return this.$store.state.bodyEl
    },

    scrollMenu () {
      return this.$store.state.scrollMenu
    },

    stopRequest () {
      return this.$store.state.stopRequest
    }
  },

  watch: {
    '$store.state.currSection' (val) {
      // Mobile menu scrolling
      if (this.$mq === 'sm' && !this.$store.state.mobAutoMenu) {
        this.mobileMenScroll(val)
      }
    },

    '$store.state.arcSection' (val) {
      // Mobile menu scrolling
      if (this.$mq === 'sm' && !this.$store.state.mobAutoMenu) {
        this.mobileMenScroll(val)
      }
    },

    '$store.state.docSection' (val) {
      // Mobile menu scrolling
      if (this.$mq === 'sm' && !this.$store.state.mobAutoMenu) {
        this.mobileMenScroll(val)
      }
    },

    stopRequest (val) {
      if (!val) {
        this.$nextTick(() => {
          this.lastS = this.bodyEl.scrollTop
          requestAnimationFrame(this.checkScroll)
        })
      }
    },

    '$store.state.backgroundColor' (val) {
      document.body.style.backgroundColor = val
    }
  },

  methods: {
    enter () {
      this.$root.$emit('doScroll')
      this.$store.commit('editState', { state: 'docMen', data: false })
    },

    checkScroll () {
      if (!this.stopRequest) {
        // Check the total child height
        // Prevent the scroll value exceeding the child height due to elastic scrolling
        let cH
        if (this.bodyEl.children[1]) {
          cH = this.bodyEl.children[1].offsetHeight - window.innerHeight
        } else {
          cH = 0
        }

        // Check the scroll position
        // Prevent a scroll value of less than 0 due to elastic scrolling
        let nS
        if (this.bodyEl.scrollTop < 0) {
          nS = 0
        } else if (this.bodyEl.scrollTop > cH) {
          nS = cH
        } else {
          nS = this.bodyEl.scrollTop
        }

        // Check the scroll direction
        let dir = 'up'
        if (nS > this.lastS) dir = 'down'
        if (nS < this.lastS) dir = 'up'
        if (nS === this.lastS) dir = this.dir

        // Set the menu to open or closed
        if (dir !== this.dir && nS > 1) {
          let scrrM = dir === 'up'
          this.$store.commit('editState', { state: 'scrollMenu', data: scrrM })
        }

        if (nS > window.outerWidth / 100 * 10) {
          this.$store.commit('editState', { state: 'docMen', data: true })
        } else {
          this.$store.commit('editState', { state: 'docMen', data: false })
        }

        // Set the menu to open or closed
        if (dir !== this.dir) {
          this.$store.commit('editState', { state: 'dir', data: dir })
        }

        this.lastS = nS
        this.dir = dir

        // Loop
        requestAnimationFrame(this.checkScroll)
      }
    }
  },

  mounted () {
    let remVal = this.$mq === 'sm' ? 10 : 4
    this.$store.commit('editState', { state: 'remVal', data: remVal })

    this.$store.commit('editState', { state: 'bodyEl', data: this.$refs.body })
    this.bodyEl.scrollTo(0, 0)
    this.lastS = this.bodyEl.scrollTop
    requestAnimationFrame(this.checkScroll)

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })

    // Create the measurement node
    let scrollDiv = document.createElement('div')
    scrollDiv.className = 'scrollbar-measure'
    document.body.appendChild(scrollDiv)

    // Get the scrollbar width
    let sbw = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.documentElement.style.setProperty('--sb', `${sbw}px`)

    // Delete the DIV
    document.body.removeChild(scrollDiv)
    document.addEventListener('contextmenu', event => event.preventDefault())
  },

  beforeCreate () {
    this.$store.dispatch('loadHome')
    this.$store.dispatch('loadExhibitions')
    this.$store.dispatch('loadArchives')
    this.$store.dispatch('loadAbout')
  }
}
</script>

<style lang="scss">
@import '../node_modules/normalize.css/normalize.css';
@import '@/assets/css/doctransitions.scss';
@import '@/assets/css/pagetransitions.scss';
@import '@/assets/css/app.scss';
@import '@/assets/css/index.scss';
@import '@/assets/css/docs.scss';
@import '@/assets/css/single.scss';
@import '@/assets/css/border.scss';

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

#app {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.line {
  margin: 3rem 0 5rem;
}

.topGap {
  margin-top: 5rem;
}

.exMenOuter {
  transition: opacity 0.3s ease, left 1s ease;
}

#exhibitionMenuOuter {
  transition: opacity 0.1s ease, left 1s ease;
}
</style>
