export default {
  data () {
    return {
      arcsScr: 0,
      docsScr: 0,
      arcDocScr: 0,
      bodScr: 0
    }
  },

  computed: {
    bodyEl () {
      return this.$store.state.bodyEl
    },

    scrollMenu () {
      return this.$store.state.scrollMenu
    },

    toIndex () {
      return this.$store.state.toIndex
    },

    fromIndex () {
      return this.$store.state.fromIndex
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.setToIndex(to)

    if (from.meta.name === 'index-doc' || from.meta.name === 'index-arc' || from.meta.name === 'index-arc-doc') {
      this.$store.commit('editState', { state: 'fromIndex', data: from.meta.name })
    } else {
      this.$store.commit('editState', { state: 'fromIndex', data: from.params.option })
    }
    next()
  },

  beforeRouteLeave (to, from, next) {
    if (to.meta.group !== from.meta.group) {
      this.$store.commit('editState', { state: 'toIndex', data: to.meta.group })
    }

    next()
  },

  methods: {
    rem4 () {
      return this.scrollMenu ? 0 : Math.round(window.outerWidth / 100 * this.$store.state.remVal)
    },

    remOffset () {
      return this.scrollMenu ? Math.round(window.outerWidth / 100 * (this.$store.state.remVal / 2)) : 0
    },

    setToIndex (to) {
      if (to.meta.name === 'index-doc' || to.meta.name === 'index-arc' || to.meta.name === 'index-arc-doc') {
        this.$store.commit('editState', { state: 'toIndex', data: to.meta.name })
      } else {
        this.$store.commit('editState', { state: 'toIndex', data: to.params.option })
      }
    },

    enter () {
      if (this.$store.state.transitionName === 'index') {
        // Get current scroll position
        this.bodSrc = this.bodyEl.scrollTop

        // Get menu height
        let rem4 = this.rem4()
        // Get offset height if menu is open
        let remOffset = this.remOffset()

        // Stop request loop for show/hide menu
        this.$store.commit('editState', { state: 'stopRequest', data: true })

        this.$nextTick(() => {
          this.bodyEl.scrollTo(0, rem4)
          let dScr = this.bodSrc - rem4

          // If entering to documents from archives
          if (this.toIndex === 'documents' && this.fromIndex === 'archives') {
            this.arcsScr = 0
            this.docsScr = 0
            document.getElementById('archives').style.top = `-${dScr}px`
            document.getElementById('documents').style.top = `-${this.docsScr}px`
          }

          // If entering to archives from documents
          if (this.toIndex === 'archives' && this.fromIndex === 'documents') {
            this.arcsScr = 0
            this.docsScr = 0
            document.getElementById('documents').style.top = `-${dScr}px`
            document.getElementById('archives').style.top = `-${this.arcsScr}px`
          }

          // If entering to documents from document
          if (this.toIndex === 'documents' && this.fromIndex === 'index-doc') {
            document.getElementById('singleDocument').style.top = `-${dScr}px`
            document.getElementById('documents').style.top = `-${this.docsScr}px`
          }

          // If entering to document from documents
          if (this.toIndex === 'index-doc' && this.fromIndex === 'documents') {
            this.$store.commit('editState', { state: 'docMen', data: false })
            this.docsScr = dScr
            document.getElementById('singleDocument').style.top = `-${remOffset}px`
            document.getElementById('documents').style.top = `-${dScr}px`
          }

          // If entering to archive from archives
          if (this.toIndex === 'index-arc' && this.fromIndex === 'archives') {
            this.$store.commit('editState', { state: 'docMen', data: false })
            this.arcsScr = dScr
            document.getElementById('singleArchive').style.top = `-${remOffset}px`
            document.getElementById('archives').style.top = `-${dScr}px`
          }

          // If entering to archive from document
          if (this.toIndex === 'index-arc' && this.fromIndex === 'index-arc-doc') {
            if (this.arcDocScr > window.outerWidth / 100 * 10) this.$store.commit('editState', { state: 'docMen', data: true })
            document.getElementById('singleDocument').style.top = `-${dScr}px`
            document.getElementById('singleArchive').style.top = `-${this.arcDocScr}px`
          }

          // If entering to archives from archive
          if (this.toIndex === 'archives' && this.fromIndex === 'index-arc') {
            document.getElementById('singleArchive').style.top = `-${dScr}px`
            document.getElementById('archives').style.top = `-${this.arcsScr}px`
          }

          // If entering to document from archive
          if (this.toIndex === 'index-arc-doc' && this.fromIndex === 'index-arc') {
            this.$store.commit('editState', { state: 'docMen', data: false })
            this.arcDocScr = dScr
            document.getElementById('singleDocument').style.top = `-${remOffset}px`
            document.getElementById('singleArchive').style.top = `-${dScr}px`
          }

          this.bodyEl.scrollTo(0, rem4 + 2)
          this.bodyEl.scrollTo(0, rem4)
        })
      }
    },

    afterEnter () {
      if (this.$store.state.transitionName === 'index') {
        let rem4 = this.rem4()
        let remOffset = this.remOffset()

        if (this.toIndex === 'archives') {
          this.bodyEl.scrollTo(0, this.arcsScr + rem4)
          document.getElementById('archives').style.top = '0px'
          this.$nextTick(() => {
            this.bodyEl.scrollTo(0, this.arcsScr + rem4 + 2)
            this.bodyEl.scrollTo(0, this.arcsScr + rem4)
          })
        }

        if (this.toIndex === 'documents') {
          this.bodyEl.scrollTo(0, this.docsScr + rem4)
          document.getElementById('documents').style.top = '0px'
          this.$nextTick(() => {
            this.bodyEl.scrollTo(0, this.docsScr + rem4 + 2)
            this.bodyEl.scrollTo(0, this.docsScr + rem4)
          })
        }

        if (this.toIndex === 'index-doc') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleDocument').style.top = '0px'
        }

        if (this.toIndex === 'index-arc-doc') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleDocument').style.top = '0px'
        }

        if (this.toIndex === 'index-arc' && this.fromIndex === 'archives') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleArchive').style.top = '0px'
        }

        if (this.toIndex === 'index-arc' && this.fromIndex === 'index-arc-doc') {
          this.bodyEl.scrollTo(0, this.arcDocScr + rem4)
          document.getElementById('singleArchive').style.top = '0px'
        }

        this.$nextTick(() => {
          this.$store.commit('editState', { state: 'stopRequest', data: false })
        })
      } else {
        this.bodyEl.scrollTo(0, 0)
      }
    }
  },

  mounted () {
    this.setToIndex(this.$route)
  }
}
