<template>
  <div class="documents" :class="{text: textImage}">
    <div v-for="(document, index) in documents" class="document" :key="index + 'doc'" :class="{noImage: !document.image.src_low, noTitle: !document.title}" @click="openDocument(document)">
      <div class="docName fadeOut">
        <span class="centerName" v-html="document.title"></span>
      </div>
      <div class="border" v-bar></div>

      <div class="docImage fadeOut" v-if="document.image.src_low">
        <img :src="document.image.src_low">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['documents', 'textImage'],

  methods: {
    openDocument (document) {
      // Index from archives
      if (this.$route.params.option === 'archives') this.$router.push(`${this.$route.params.archive}/documents/${document.slug}`)
      // Index from documents
      if (this.$route.params.option === 'documents') this.$router.push(`documents/${document.slug}`)
      // Exhibition
      if (this.$route.meta.group === 'exhibition') this.$router.push(`${this.$route.path}/documents/${document.slug}`)
      // About
      if (this.$route.meta.group === 'about') this.$router.push(`${this.$route.path}/collateral/${document.slug}`)
    }
  }
}
</script>
