<template>
  <div class="subtitle fadeOut">
    <span v-html="content"></span>
  </div>
</template>

<script>
export default {
  props: ['content']
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.subtitle {
  width: 100%;
  padding: 7rem 15rem calc(#{$sectionpadding} + 7rem);
  font-family: $magister;
  font-size: $fontsubt;
  font-feature-settings: "case";
  text-align: center;
  line-height: 1.15;
}

#sections .subtitle {
  padding: 7rem 0 calc(#{$sectionpadding} + 7rem);
}

#archives,
#documents {
  .subtitle {
    padding: calc(#{$sectionpadding} + 7rem) 15rem;
  }
}

.singleDoc,
.singleArc {
  .subtitle {
    padding-top: calc(#{$sectionpadding} + 7rem);
    padding-bottom: 7rem;
  }
}

.singleColl {
  .subtitle {
    padding-top: calc(#{$sectionpadding} + 7rem);
  }
}

@media screen and (max-width: $breakPoint1) {
  .subtitle {
    font-size: 2.7rem;
    padding: 7rem 0 calc(#{$sectionpadding} + 7rem);
    line-height: 1.2;

    br {
      display: none;
    }
  }

  #archives,
  #documents {
    .subtitle {
      padding: calc(#{$sectionpadding} + 7rem) 0;
    }
  }
}
</style>
