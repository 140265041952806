import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
// Normal components
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Exhibition from '@/views/exhibition/Exhibition.vue'
import Index from '@/views/index/Index.vue'

Vue.use(Router)

const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    return new Promise(resolve => {
      this.app.$root.$once('doScroll', () => {
        store.state.bodyEl.scrollTo(0, 0)
      })
    })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang',
      name: 'home',
      component: Home,
      meta: {
        group: 'home'
      }
    },
    {
      path: '/:lang/about',
      name: 'about',
      component: About,
      meta: {
        noMenuScr: true,
        name: 'documents',
        group: 'about'
      },
      children: [
        {
          path: 'collateral/:name?',
          name: 'ab-coll',
          meta: {
            noMenuScr: true,
            name: 'index-doc',
            group: 'about'
          }
        }
      ]
    },
    {
      path: '/:lang/index/:option',
      name: 'index',
      component: Index,
      meta: {
        noMenuScr: true,
        name: 'index',
        group: 'index'
      },
      children: [
        {
          path: 'archive/:archive?',
          name: 'index-arc',
          meta: {
            noMenuScr: true,
            name: 'index-arc',
            group: 'index'
          },
          children: [
            {
              path: 'documents/:doc?',
              name: 'index-arc-doc',
              meta: {
                noMenuScr: true,
                name: 'index-arc-doc',
                group: 'index'
              }
            }
          ]
        },
        {
          path: ':doc',
          name: 'index-doc',
          meta: {
            noMenuScr: true,
            name: 'index-doc',
            group: 'index'
          }
        }
      ]
    },
    {
      path: '/:lang/exhibition/:name',
      name: 'exhibition',
      component: Exhibition,
      meta: {
        name: 'index',
        group: 'exhibition'
      },
      children: [
        {
          path: 'archive/:archive?',
          name: 'ex-arc',
          meta: {
            noMenuScr: true,
            name: 'index-arc',
            group: 'exhibition'
          },
          children: [
            {
              path: 'documents/:doc?',
              name: 'ex-arc-doc',
              meta: {
                noMenuScr: true,
                name: 'index-arc-doc',
                group: 'exhibition'
              }
            }
          ]
        },
        {
          path: 'documents/:doc',
          name: 'ex-doc',
          meta: {
            noMenuScr: true,
            name: 'index-doc',
            group: 'exhibition'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/en'
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.commit('editState', { state: 'stopRequest', data: true })

  // Page transitions
  if (!store.state.internalNavigation) {
    if (to.name === 'home') {
      store.commit('editState', { state: 'transitionMain', data: 'home' })
    }

    if (to.name !== 'home') {
      store.commit('editState', { state: 'transitionMain', data: 'page' })
    }
  } else {
    store.commit('editState', { state: 'transitionMain', data: 'none' })
  }

  // Key for transitions
  if (to.meta.group === 'exhibition' && from.meta.group === 'exhibition') {
    store.commit('editState', { state: 'pageKey', data: to.params.name + to.params.lang })
  }

  if (to.meta.group === 'about') {
    store.commit('editState', { state: 'pageKey', data: to.meta.group + to.params.lang })
  }

  if (to.meta.group === 'index') {
    store.commit('editState', { state: 'pageKey', data: to.meta.group + to.params.lang })
  }

  if (to.name === 'home') {
    store.commit('editState', { state: 'pageKey', data: to.meta.group + to.params.lang })
  }

  // Language checks
  if (to.params.lang === 'en' || to.params.lang === 'it') {
    store.commit('editState', { state: 'lan', data: to.params.lang })

    if (to.meta.group === 'exhibition') {
      // If going within same exhibition
      if (from.params.name === to.params.name) {
        store.commit('editState', { state: 'transitionName', data: 'index' })
      } else {
        store.commit('editState', { state: 'transitionName', data: 'no' })
      }
    } else if (to.meta.group === 'index') {
      // If going within same exhibition
      if (to.meta.group === 'index' && from.meta.group === 'index' && !store.state.mainMenu) {
        store.commit('editState', { state: 'transitionName', data: 'index' })
      } else {
        store.commit('editState', { state: 'transitionName', data: 'no' })
      }
    } else if (to.meta.group === 'about') {
      if (to.meta.group === 'about' && from.meta.group === 'about') {
        store.commit('editState', { state: 'transitionName', data: 'index' })
      } else {
        store.commit('editState', { state: 'transitionName', data: 'no' })
      }
    }
  } else {
    let route = to.path
    store.commit('editState', { state: 'lan', data: 'en' })
    router.replace({ path: `/en${route}` })
  }

  next()
})

router.afterEach((to, from, next) => {
  store.commit('editState', { state: 'stopRequest', data: false })
})

export default router
