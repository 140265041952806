<template>
  <div>
    <Section menu="inro" id="inro" v-current:inro>
      <template #scrtop><div ref="inro"></div></template>
      <template #header>
        <Subtitle :content="exhibition.header.text" />
      </template>
      <template #content>
        <Textblock :content="exhibition.project" />
      </template>
    </Section>

    <Section menu="scen" :key="exhibition.slug + 'scen'" v-current:scen>
      <template #title>{{exhibition.scenario.title}}</template>
      <template #scr><div ref="scen" id="scen"></div></template>
      <template #content>
        <Slideshow :slides="exhibition.scenario.images" sec="scen"/>
      </template>
    </Section>

    <Section menu="cura" v-current:cura>
      <template #title>{{exhibition.curators.title}}</template>
      <template #scr><div ref="cura" id="cura"></div></template>
      <template #content>
        <Textblock class="topGap" :content="exhibition.curators.text" />
      </template>
    </Section>

    <Section menu="inst" :key="exhibition.slug + 'views'" v-current:inst>
      <template #title>{{exhibition.installation.title}}</template>
      <template #scr><div ref="inst" id="inst"></div></template>
      <template #content>
        <Slideshow :slides="exhibition.installation.images" sec="inst"/>
      </template>
    </Section>

    <Section menu="docs" v-current:docs :exhibition="exhibition">
      <template #title>{{$store.state.translations.selectedDocs[$store.state.lan]}}</template>
      <template #scr><div ref="docs" id="docs"></div></template>
      <template #content>
        <Documents :documents="sortDocs" :textImage="exhibition.doc_text_view" />
      </template>
    </Section>

    <Section menu="arcs" class="noBorder" v-current:arcs :exhibition="exhibition">
      <template #title>{{$store.state.translations.selectedArcs[$store.state.lan]}}</template>
      <template #scr><div ref="arcs" id="arcs"></div></template>
      <template #content>
        <Archives :archives="allArchives" :textImage="exhibition.arc_text_view" />
      </template>
    </Section>

    <Footer />
  </div>
</template>

<script>
import Subtitle from '@/components/Subtitle.vue'
import Textblock from '@/components/Textblock.vue'
import Slideshow from '@/components/Slideshow.vue'
import Section from '@/views/exhibition/Section.vue'
import Documents from '@/views/index/AllDocuments.vue'
import Archives from '@/views/index/AllArchives.vue'
import Footer from '@/components/Footer.vue'
import ScrollDirective from '@/mixins/DirectiveScroll'
import ScrollMixin from '@/mixins/MixinScroll'
import Vue from 'vue'
Vue.directive('current', ScrollDirective)

export default {
  props: ['exhibition'],

  mixins: [ScrollMixin],

  components: {
    Footer,
    Subtitle,
    Textblock,
    Slideshow,
    Section,
    Documents,
    Archives
  },

  computed: {
    sortDocs () {
      let sortedDocs = this.exhibition.documents.slice()
      return sortedDocs.sort((a, b) => a.title.localeCompare(b.title))
    },

    allArchives () {
      let arr = this.exhibition.documents
      const unique = arr.map(e => e['archive_slug']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map(e => {
        let docs = arr.filter(item => item.archive_slug === arr[e].archive_slug)

        let rObj = {
          archive: arr[e].archive,
          archive_image: arr[e].archive_image,
          archive_slug: arr[e].archive_slug,
          documents: docs
        }
        return rObj
      })

      return unique.sort((a, b) => a.archive.text.localeCompare(b.archive.text))
    }
  },

  watch: {
    currentSection (val) {
      // Commit current section to store
      if (val) this.$store.commit('editState', { state: 'currSection', data: val })
    }
  }
}
</script>
