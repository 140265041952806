<template>
  <div class="expo menuBorder" :class="{flexOpen: openEx && open === index}" :style="[hoverColour, offSet]" @click="openExpo(index)">
    <div class="expoInner">
      <span class="cur" v-html="exhibition.curator"></span>
      <span class="dates">I-DEA<br>{{exhibition.start_date}} — {{exhibition.end_date}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['exhibition', 'index', 'open', 'openEx', 'menu'],

  computed: {
    hoverColour () {
      return {
        '--color-hover': this.exhibition.colour
      }
    },

    offSet () {
      let offset
      let trans
      if (this.openEx) {
        if (this.index < this.open) {
          let offam = 100 * this.open
          offset = '-4rem'
          trans = `translateY(-${offam}%)`
        }

        if (this.index === this.open) {
          let offam = 100 / 5 * this.open
          let offam2 = 5 - this.open
          offset = `calc(-${offam}% - 4rem / 5 * ${offam2})`
          trans = `translateY(0)`
        }

        if (this.index > this.open) {
          let offam = 100 * (5 - this.open - 1)
          offset = '4rem'
          trans = `translateY(${offam}%)`
        }

        return {
          'transform': trans,
          'top': offset
        }
      } else {
        return null
      }
    }
  },

  methods: {
    openExpo (index) {
      if (this.exhibition.slug !== this.$route.params.name) {
        this.$store.commit('editState', { state: 'menuAnimation', data: '' })
        this.$parent.open = index
        this.$parent.openEx = true
        this.$store.commit('editState', { state: 'hideContent', data: true })

        window.setTimeout(() => {
          this.$store.commit('editState', { state: 'internalNavigation', data: true })
          this.$root.$emit('doScroll')
          this.$router.push(`/${this.$store.state.lan}/exhibition/${this.exhibition.slug}`, () => {
            this.$store.commit('editState', { state: 'internalNavigation', data: false })
          })
        }, 750)

        window.setTimeout(() => {
          this.$store.commit('editState', { state: 'hideContent', data: false })
          this.menu.style.display = 'none'
          this.$nextTick(() => {
            this.$store.commit('editState', { state: 'mainMenu', data: false })
            this.$parent.open = null
            this.$parent.openEx = false
          })
        }, 800)
      } else {
        // Close menu
        this.$store.commit('editState', { state: 'mainMenu', data: false })
        window.setTimeout(() => {
          // Show content
          this.$store.commit('editState', { state: 'hideContent', data: false })
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.expo:hover {
  background-color: var(--color-hover);
}

.flexOpen {
  &.menuBorder::before {
    transition: top 0.2s ease;
    top: -$borderthick;
  }

  background-color: var(--color-hover);
}

.expo.flexOpen {
  height: calc(100% + 4rem);

  .dates {
    display: block;
  }

  .cur {
    display: none;
  }
}
</style>
