<template>
  <div class="sectionOuter" ref="section" :class="menu">
    <div class="section">
      <slot name="scrtop"></slot>
      <slot name="header"></slot>
      <div class="sectionTitle fadeOut">
        <slot name="title"></slot>
      </div>

      <template v-if="menu === 'docs' && $mq === 'sm'">
        <div class="titleBorder"><div class="border" v-bar></div></div>
        <div class="imageTextContextual" :style="{backgroundColor: $store.state.backgroundColor}">
          <div class="fadeOut">
            <div class="textImSel">
              <div :class="{iselected: exhibition.doc_text_view}" @click="exhibition.doc_text_view = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !exhibition.doc_text_view}" @click="exhibition.doc_text_view = false">{{$store.state.translations.image[$store.state.lan]}}</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="menu === 'arcs' && $mq === 'sm'">
        <div class="titleBorder"><div class="border" v-bar></div></div>
        <div class="imageTextContextual" :style="{backgroundColor: $store.state.backgroundColor}">
          <div class="fadeOut">
            <div class="textImSel">
          <div :class="{iselected: exhibition.arc_text_view}" @click="exhibition.arc_text_view = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !exhibition.arc_text_view}" @click="exhibition.arc_text_view = false">{{$store.state.translations.image[$store.state.lan]}}</div>
            </div>
          </div>
        </div>
      </template>

      <slot name="scr"></slot>
      <slot name="content"></slot>
      <slot name="content2"></slot>
    </div>
    <div class="border" v-bar></div>
  </div>
</template>

<script>
export default {
  props: ['menu', 'exhibition']
}
</script>
