<template>
  <div id="sections" class="tranSec" key="tranSec">
    <div class="secContainer">
      <Section menu="inro" id="inro" v-current:inro>
        <template #scrtop><div ref="inro"></div></template>
        <template #header>
          <Subtitle :content="exhibition.header" />
        </template>
        <template #content>
          <Textblock :content="exhibition.project.text" />
        </template>
      </Section>

      <div class="sectionOuter" v-current:news v-if="exhibition.news">
        <div class="section">
          <div class="sectionTitle fadeOut">{{exhibition.news.title}}</div>
          <div ref="news" id="news"></div>
          <div class="newsItem" v-for="(item, index) in exhibition.news" :key="item.date + index">
            <Textblock class="date" :content="item.date"/>
            <Textblock :content="item.news"/>
          </div>
        </div>
        <div class="border" v-bar></div>
      </div>

      <Section menu="inst" :key="exhibition.slug + 'views'" v-current:inst>
        <template #title>{{exhibition.venue.title}}</template>
        <template #scr><div ref="inst" id="inst"></div></template>
        <template #content>
          <Slideshow :slides="exhibition.venue.images" sec="inst"/>
        </template>

        <template #content2 v-if="exhibition.venue.text">
          <Textblock :content="exhibition.venue.text" />
        </template>
      </Section>

      <Section v-current:coll :exhibition="exhibition" v-if="exhibition.collateral" class="collSec">
        <template #title>Collateral</template>
        <template #scr><div ref="coll" id="coll"></div></template>
        <template #content>
          <Documents :documents="exhibition.collateral" :textImage="$store.state.collateralText" />
        </template>
      </Section>

      <div class="sectionOuter" v-current:team>
        <div class="section">
          <div class="teamItem" v-for="(item, index) in exhibition.team.items" :key="item.title">
            <div class="sectionTitle fadeOut" v-html="item.title"></div>
            <div v-if="index === 0" ref="team" id="team"></div>
            <div class="teamBlock fadeOut" v-html="item.item" :class="{thanks: index === exhibition.team.items.length - 1}"></div>
            <div class="border" v-bar></div>
          </div>
        </div>
        <div class="border" v-bar></div>
      </div>

      <div class="sectionOuter" v-current:part v-if="exhibition.partners">
        <div class="section">
          <div class="sectionTitle fadeOut">{{exhibition.partners.title}}</div>
          <div ref="part" id="part"></div>
          <div class="partners">
            <div class="partnerItem" v-for="(item, index) in exhibition.partners.items" :key="item.title + index">
              <div class="partnerTitle" v-html="item.title"></div>
              <div class="partnerImageOuter">
                <div class="partnerImage">
                  <img :src="item.image.src_low">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border" v-bar></div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Subtitle from '@/components/Subtitle.vue'
import Documents from '@/views/index/AllDocuments.vue'
import Textblock from '@/components/Textblock.vue'
import Slideshow from '@/components/Slideshow.vue'
import Section from '@/views/exhibition/Section.vue'
import Footer from '@/components/Footer.vue'
import ScrollDirective from '@/mixins/DirectiveScroll'
import ScrollMixin from '@/mixins/MixinScroll'
import Vue from 'vue'

Vue.directive('current', ScrollDirective)

export default {
  props: ['exhibition'],

  mixins: [ScrollMixin],

  components: {
    Subtitle,
    Documents,
    Textblock,
    Slideshow,
    Section,
    Footer
  },

  watch: {
    currentSection (val) {
      // Commit current section to store
      this.$store.commit('editState', { state: 'aboutSection', data: val })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.teamItem {
  margin: 0 $contentpadding;
  position: relative;
}

.teamBlock {
  column-count: 3;
  padding: 5rem 0;
  margin-bottom: 5rem;
  font-family: $magister;
  position: relative;

  p {
    display: inline-block;
    margin-bottom: 1.75rem;
  }

  &.thanks {
    column-count: 1;
    text-align: justify;
  }
}

.teamItem:last-child {
  .teamBlock {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .border {
    display: none;
  }
}

.newsItem {
  margin-top: 5rem;
}

.partners {
  margin: 0 $contentpadding;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: $padding;

  .partnerItem {
    padding-top: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .partnerImageOuter {
    padding-bottom: 50%;
    position: relative;
  }

  .partnerImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 50%;
    max-height: 50%;
    width: auto;
    height: auto;
  }
}

.secContainer .sectionOuter:last-child {
  .border {
    display: none;
  }

  .teamItem .border {
    display: block;
  }

  .teamItem:last-child .border {
    display: none;
  }
}

.about .collSec .section + .border {
  display: none;
}

@media screen and (max-width: $breakPoint1) {
  .teamItem {
    margin: 0 0;
  }

  .partners {
    margin: 0 0;
    grid-template-columns: repeat(2, 1fr);
  }

  .teamBlock {
    column-count: 1;

    p {
      display: block;
    }
  }
}
</style>
