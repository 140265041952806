<template>
  <div id="exhibitionMenus" :style="{backgroundColor: $store.state.backgroundColor}">
    <FloatingMenu />
    <ExhibitionMenu />
  </div>
</template>

<script>
import FloatingMenu from '@/components/menu/FloatingMenu.vue'
import ExhibitionMenu from '@/components/menu/ExhibitionMenu.vue'

export default {
  components: {
    FloatingMenu,
    ExhibitionMenu
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#exhibitionMenusSpacer {
  position: relative;
  height: 8rem;
  width: 100%;
}

#exhibitionMenus {
  position: sticky;
  top: -4rem;
  transition: top 0.3s ease;
  z-index: 10;
  left: 0;
  right: 0;
}

.scrollMenu #exhibitionMenus {
  top: 0;
}
</style>
