<template>
  <div class="footer">
    <div class="footerLeft fadeOut" v-if="$store.state.about" v-html="$store.state.about.footer"></div>
    <div class="footerRight fadeOut">
      <div class="textImSel">
        <div :key="$store.state.lan" :class="{iselected: $store.state.lan === 'it'}" @click="changeLanguage('it')">Italian<span>,</span></div>&nbsp;<div :class="{iselected: $store.state.lan === 'en'}" @click="changeLanguage('en')">English</div>
      </div>
    </div>
  </div>
</template>

<script>
import MixinLan from '@/mixins/MixinLan'

export default {
  mixins: [MixinLan]
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.footer {
  display: flex;
  padding: .9rem 0;
  align-items: flex-start;
  margin-top: 15rem;
  border-top: $border;

  p {
    margin: 0;
  }
}

.footerRight {
  margin-left: auto;
}

@media screen and (max-width: $breakPoint1) {
  .footer {
    .textImSel {
      align-items: flex-start;
      text-align: left;
      flex-direction: column;
    }
  }
}
</style>
