
<template>
  <div id="singleDocument" class="singleColl" v-if="document">

    <div class="sectionOuter" v-current:doin>
      <div id="doin"></div>
      <div class="section">
        <div class="image fadeOut" v-if="document.image.src_low" :key="document.image.src_low">
          <div class="imageInner">
            <img :src="document.image.src_low">
            <div class="caption" v-if="document.image.desc" v-html="document.image.desc"></div>
          </div>
        </div>

        <div class="video fadeOut" v-else-if="document.video" :key="document.video.url">
          <div class="imageInner">
            <div class="playHover" @click="playVideo" @mousemove="hidePause" :class="{playing: playing, pause: pause}">
              <div class="playButton"></div>
              <div class="pauseButton">
                <div></div>
                <div></div>
              </div>
            </div>

            <video playsinline loop ref="video">
              <source :src="document.video.url" type="video/mp4">
            </video>
            <div class="caption" v-if="document.video.desc" v-html="document.video.desc"></div>
          </div>
        </div>

        <div class="audio" v-else-if="document.audio" :key="document.audio.url">
          <div class="playHover" @click="playVideo" @mousemove="hidePause" :class="{playing: playing, pause: pause}">
            <div class="playButton"></div>
            <div class="pauseButton">
              <div></div>
              <div></div>
            </div>
          </div>

          <div class="imageInner">
            <audio controls ref="video">
              <source :src="document.audio.url">
            </audio>
          </div>
        </div>

        <Subtitle :content="document.title" />
        <Textblock :content="document.project.text" />
      </div>
      <div class="border" v-bar></div>
    </div>

    <Section class="collNo" :key="about.slug + 'views'" v-current:dets>
      <template #title>{{document.scenario.title}}</template>
      <template #scr><div ref="dets" id="dets"></div></template>
      <template #content>
        <Slideshow :slides="document.scenario.images" sec="dets"/>
      </template>

      <template #content2 v-if="document.scenario.text">
        <Textblock :content="document.scenario.text" />
      </template>
    </Section>

    <Footer />
  </div>
</template>

<script>
import Section from '@/views/exhibition/Section.vue'
import Textblock from '@/components/Textblock.vue'
import Slideshow from '@/components/Slideshow.vue'

import Subtitle from '@/components/Subtitle.vue'
import Footer from '@/components/Footer.vue'
import ScrollDirective from '@/mixins/DirectiveScroll'
import ScrollMixin from '@/mixins/MixinScroll'
import Vue from 'vue'

Vue.directive('current', ScrollDirective)

export default {
  data () {
    return {
      playing: false,
      pause: false,
      timer: false
    }
  },

  props: ['about'],

  mixins: [ScrollMixin],

  components: {
    Textblock,
    Slideshow,
    Section,
    Subtitle,
    Footer
  },

  computed: {
    document () {
      if (this.about.collateral) {
        return this.about.collateral.find(page => page.slug === this.$route.params.name)
      } else {
        return null
      }
    }
  },

  methods: {
    playVideo () {
      let video = this.$refs.video
      if (this.playing) {
        this.playing = false
        video.pause()
      } else {
        this.playing = true
        video.play()
      }
    },

    hidePause () {
      if (this.timer) clearTimeout(this.timer)

      this.pause = false
      this.timer = window.setTimeout(() => {
        this.pause = true
      }, 1000)
    }
  },

  watch: {
    document: {
      immediate: true,
      handler (val) {
        if (val) this.$store.commit('editState', { state: 'docTitle', data: this.document.title })
      }
    },

    currentSection (val) {
      // Commit current section to store
      this.$store.commit('editState', { state: 'docSection', data: val })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.collNo .border {
  display: none;
}

.playHover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.playButton {
  opacity: 1;
  width: 0;
  height: 0;
  border-top: 7rem solid transparent;
  border-bottom: 7rem solid transparent;
  border-left: calc(7rem * 2) solid white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  transition: opacity 0.2s ease;
}

.pauseButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 14rem;
  width: 10rem;
  transition: opacity 0.2s ease;
  opacity: 0;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  display: flex;

  div {
    width: 3.5rem;
    height: 100%;
    background-color: white;
  }

  div:last-child {
    margin-left: auto;
  }
}

.playHover.playing {
  .playButton {
    opacity: 0;
  }

  &:hover {
    .pauseButton {
      opacity: 1;
    }

  &:hover.pause {
    .pauseButton {
      opacity: 0;
    }
  }
  }
}

.audio {
  .imageInner {
    height: 0;
    visibility: hidden;
  }

  .playHover {
    top: 2.5rem;
  }
}

@media screen and (max-width: $breakPoint1) {
  .playButton {
    border-top: 4rem solid transparent;
    border-bottom: 4rem solid transparent;
    border-left: calc(4rem * 2) solid white;
  }

  .pauseButton {
    height: 8rem;
    width: 6rem;

    div {
      width: 2rem;
    }
  }
}
</style>
