<template>
  <div class="slideshow fadeOut">
    <div class="slideshowInner" ref="slide">
      <div class="slide" v-for="slide in slides" :key="slide.src_low">
        <img :src="slide.src_blur" :data-flickity-lazyload="slide.src_low"/>
        <div class="caption" v-if="slide.desc" v-html="slide.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'
import VueScrollTo from 'vue-scrollto'
import Vue from 'vue'

Vue.use(VueScrollTo)

export default {
  props: ['slides', 'sec'],

  data () {
    return {
      flickOp: {
        lazyLoad: 2,
        cellAlign: 'center',
        prevNextButtons: false,
        cellSelector: '.slide',
        setGallerySize: false,
        pageDots: false,
        wrapAround: true
      },
      flickity: null
    }
  },

  methods: {
    next (index, posX) {
      let curI = this.flickity.selectedIndex
      let lr = posX > window.outerWidth / 2 ? 'right' : 'left'

      if (index === curI) {
        this.flickity.next()
      } else if (lr === 'right') {
        this.flickity.next()
      } else {
        this.flickity.previous()
      }
    },

    initSlider () {
      this.$nextTick(() => {
        let that = this
        this.flickity = new Flickity(this.$refs.slide, this.flickOp)
        this.flickity.resize()
        this.flickity.on('staticClick', (event, pointer, cellElement, cellIndex) => {
          if (cellElement) {
            this.goTo()
            this.flickity.resize()
            that.next(cellIndex, event.clientX)
          }
        })
      })
    },

    goTo () {
      this.$store.commit('editState', { state: 'stopRequest', data: true })
      this.$store.commit('editState', { state: 'scrollMenu', data: false })

      this.$nextTick(() => {
        let options = {
          container: '#app',
          easing: 'ease',
          force: true,
          offset: (window.outerWidth / 100 * 3) * -1,
          cancelable: false,
          onDone: () => {
            this.$nextTick(() => {
              this.$store.commit('editState', { state: 'stopRequest', data: false })
            })
          },
          x: false,
          y: true
        }

        let div = document.getElementById(this.sec)
        this.$scrollTo(div, 500, options)
      })
    }
  },

  mounted () {
    this.initSlider()
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.sectionOuter.scen,
.sectionOuter.inst {
  padding-bottom: 2rem;
}

.about .sectionOuter.inst {
  padding-bottom: 5rem;
}

.slideshow {
  width: 100%;
  height: calc(var(--vh) * 95);
  max-height: 50vmax;
  position: relative;
  padding: 3rem 0 0;
  margin-top: 2rem;

  * {
    outline: none;
  }
}

.slideshowInner {
  position: relative;
  height: 100%;
  width: 100%;
}

.slideshowInner:before {
  content: " ";
  background-color: black;
  z-index: 2;
  width: $borderthick;
  top: 0;
  bottom: 5rem;
  position: absolute;
  left: 0;
}

.slideshowInner:after {
  content: " ";
  background-color: black;
  z-index: 2;
  width: $borderthick;
  top: 0;
  bottom: 5rem;
  position: absolute;
  right: 0;
}

.flickity-viewport {
  overflow-x: hidden;
  overflow-y: visible;
  height: 100%;
  top: 0;
  left: 1px;
  right: 1px;
  position: absolute;
  z-index: 1;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.slide {
  position: relative;
  float: left;
  height: 100%;
  cursor: pointer;
  margin: 0 $padding;
  padding: 0 0 5rem;

  img {
    pointer-events: none;
    height: 100%;
    width: auto;
  }

  .caption {
    line-height: 1.2;
    margin-top: 0.5rem;
    position: absolute;
    font-size: $fontcapt;
    display: flex;
    align-items: flex-end;
    left: 0;
    transition: opacity 0.2s ease;
    opacity: 0;
  }
}

.slide.is-selected .caption {
  opacity: 1;
}

@media (orientation: portrait) {
  .slideshow {
    height: 25rem;
    max-height: auto;
  }
}

@media screen and (max-width: $breakPoint1) {
  .slide {
    margin: 0 0.5rem;
  }
}
</style>
