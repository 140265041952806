<template>
  <div id="top" ref="top" :key="exhibition.title" class="pagefade" :class="{show: show}">
    <div id="header">
      I-DEA<br>{{exhibition.start_date}} — {{exhibition.end_date}}
    </div>

    <div id="image" :key="exhibition.image.src_low">
      <div class="imageInner">
        <img @load="show = true" :src="exhibition.image.src_low">
        <div class="caption" v-if="exhibition.image.desc" v-html="exhibition.image.desc"></div>
      </div>
    </div>

    <div id="title">
      <div id="titleInner" v-html="exhibition.subtitle"></div>
    </div>
    <div id="curator" v-html="exhibition.curator"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  },

  props: ['exhibition'],

  beforeDestroy () {
    this.show = false
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#top {
  font-family: $horizontal;
  line-height: $horizontalLeading;
  font-size: $fontmenu;
  text-align: center;
  left: 0%;
  position: relative;

  #image,
  #title {
    opacity: 0;
    transition: opacity 0.5s ease 0.2s;
  }

  &.show {
    #image,
    #title {
      opacity: 1;
    }
  }

  #header {
    position: sticky;
    top: 0;
    height: $headergap;
    margin-bottom: calc(var(--vh) * 100 - #{$headergap});
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: calc(1rem - #{$borderthick}) 0 0;
  }

  #image {
    position: relative;
    width: 100%;
    height: calc(var(--vh) * 100 - #{$headergap} * 2);
    padding: 2.5rem 3rem 3.5rem;
    margin-top: calc(var(--vh) * -100 + #{$headergap});
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageInner {
    position: relative;

    .caption {
      position: absolute;
      line-height: 1.2;
      margin-top: 0.5rem;
      font-family: $magister;
      font-size: $fontcapt;
      left: 0;
      text-align: left;
    }
  }

  #title {
    position: sticky;
    top: calc(var(--vh) * 50 - #{$headergap} / 2);
    height: calc(var(--vh) * 50 + #{$headergap} / 2);
  }

  #titleInner {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    height: #{$headergap};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 1.3rem;
  }

  #curator {
    text-transform: uppercase;
    margin-top: calc(var(--vh) * 50 - #{$headergap} / 2 - #{$headergap} - 3rem);
    height: $headergap;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 1.5rem;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: $padding;
      right: $padding;
      border-bottom: $border;
    }
  }

  img {
    pointer-events: none;
    max-width: 100%;
    max-height: calc(var(--vh) * 100 - #{$headergap} * 2 - 7rem);
    width: auto;
    height: auto;
  }
}

@media (orientation: portrait) {
  #top {
    font-size: $fontmenumob;
  }
}

@media screen and (max-width: $breakPoint1) {
  #top {
    font-size: $fontmenumob;
  }
}
</style>
