export default {
  methods: {
    changeLanguage (lan) {
      let newLan = lan

      let p = this.$route.path
      let regex = `/${this.$store.state.lan}`
      let newP = p.replace(regex, `/${newLan}`)
      // this.$store.commit('editState', { state: 'docMen', data: false })
      this.$store.commit('editState', { state: 'stopRequest', data: true })
      this.$store.commit('editState', { state: 'lan', data: newLan })
      this.$store.commit('editState', { state: 'scrollMenu', data: true })
      this.$store.dispatch('loadHome')
      this.$store.dispatch('loadExhibitions')
      this.$store.dispatch('loadArchives')
      this.$store.dispatch('loadAbout')
      this.$router.push({ path: newP })
    }
  }
}
