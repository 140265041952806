<template>
  <div class="textblock">
    <span v-html="content"></span>
  </div>
</template>

<script>
export default {
  props: ['content']
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.textblock {
  width: 100%;
  padding: 0 $contentpadding;
  font-family: $magister;
  text-align: justify;
  hyphens: auto;

  p {
    margin: 0 0 0;
    text-indent: 2.5rem;
  }

  .citation {
    text-indent: 0;
    margin: 0 0 1.2em;
  }

  p:first-child,
  .citation + p {
    text-indent: 0;
  }
}

.slideshow + .textblock {
  padding-top: 3rem;
}

.date {
  font-style: italic;
}

@media screen and (max-width: $breakPoint1) {
  .textblock {
    padding: 0;
  }
}
</style>
