<template>
  <div class="documents archives" :class="{text: textImage}">
    <div class="gridLine"></div>
    <div class="gridLine"></div>
    <div class="gridLine"></div>

    <div v-for="(document, index) in allArchives" :key="index + 'doc'" @click="openArchive(document)">
      <div v-if="document.documents.length" class="document" :class="{noImage: !document.archive_image.src_low, noTitle: !document.archive}">
        <div class="docName fadeOut">
          <span class="centerName" v-html="document.archive.text"></span>
          <div class="selectedNumber">{{$store.state.translations.selectedDocs[$store.state.lan]}} <span class="num" v-html="document.documents.length"></span></div>
        </div>

        <div class="border" v-bar></div>

        <div class="docImage fadeOut" v-if="document.archive_image.src_low">
          <img :src="document.archive_image.src_low">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['archives', 'textImage'],

  computed: {
    allArchives () {
      return this.archives
    }
  },

  methods: {
    openArchive (document) {
      // Index from archives
      if (this.$route.params.option === 'archives') this.$router.push(`/${this.$store.state.lan}/index/archives/archive/${document.archive_slug}`)
      // Exhibition
      if (this.$route.meta.group === 'exhibition') this.$router.push(`${this.$route.path}/archive/${document.archive_slug}`)
    }
  }
}
</script>
