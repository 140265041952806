<template>
  <div id="singleArchive" class="singleArc" v-if="archive">
    <div class="sectionOuter" v-current:doin>
      <div id="doin"></div>
      <div class="section">
        <div class="image fadeOut" v-if="archive.archive_image.src_low" :key="archive.archive_image.src_low">
          <div class="imageInner">
            <img :src="archive.archive_image.src_low">
            <div class="caption" v-if="archive.archive_image.desc" v-html="archive.archive_image.desc"></div>
          </div>
        </div>

        <Subtitle :content="archive.archive.text" />
      </div>
    </div>

    <div id="dets"></div>
    <div class="sectionOuter selecDocs" v-current:dets>
      <div class="section">
        <div v-if="archive.property.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.property.title"></div>
            <div class="atRight fadeOut" v-html="archive.property.text"></div>
          </div>
        </div>

        <div v-if="archive.bibliography.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.bibliography.title"></div>
            <div class="atRight fadeOut" v-html="archive.bibliography.text"></div>
          </div>
        </div>

        <div v-if="archive.condition.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.condition.title"></div>
            <div class="atRight fadeOut" v-html="archive.condition.text"></div>
          </div>
        </div>

        <div v-if="archive.contents.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.contents.title"></div>
            <div class="atRight fadeOut" v-html="archive.contents.text"></div>
          </div>
        </div>

        <div v-if="archive.history.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.history.title"></div>
            <div class="atRight fadeOut" v-html="archive.history.text"></div>
          </div>
        </div>

        <div v-if="archive.keywords.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.keywords.title"></div>
            <div class="atRight fadeOut" v-html="archive.keywords.text"></div>
          </div>
        </div>

        <div v-if="archive.location.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.location.title"></div>
            <div class="atRight fadeOut" v-html="archive.location.text"></div>
          </div>
        </div>

        <div v-if="archive.number.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.number.title"></div>
            <div class="atRight fadeOut" v-html="archive.number.text"></div>
          </div>
        </div>

        <div v-if="archive.period.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.period.title"></div>
            <div class="atRight case fadeOut" v-html="archive.period.text"></div>
          </div>
        </div>

        <div v-if="archive.purpose.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.purpose.title"></div>
            <div class="atRight fadeOut" v-html="archive.purpose.text"></div>
          </div>
        </div>

        <div v-if="archive.space.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.space.title"></div>
            <div class="atRight fadeOut" v-html="archive.space.text"></div>
          </div>
        </div>

        <div v-if="archive.typology.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.typology.title"></div>
            <div class="atRight fadeOut" v-html="archive.typology.text"></div>
          </div>
        </div>

        <div v-if="archive.website.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.website.title"></div>
            <div class="atRight fadeOut hyphenUrl">
              <a :href="archive.website.text">
                <span v-if="archive.website.url_text" v-html="archive.website.url_text"></span>
                <span v-else v-html="archive.website.text"></span>
              </a>
            </div>
          </div>
        </div>

        <div v-if="archive.digitised.text" class="docAtt">
          <div class="docAttInner">
            <div class="border borderTop" v-bar></div>
            <div class="atLeft fadeOut" v-html="archive.digitised.title"></div>
            <div class="atRight fadeOut" v-html="archive.digitised.text"></div>
          </div>
        </div>

      </div>
      <div class="border" v-bar></div>
    </div>

    <div class="sectionOuter" v-current:docs>
      <div class="section">
        <div class="sectionTitle fadeOut">
          {{$store.state.translations.selectedDocs[$store.state.lan]}}
        </div>
        <div id="docs"></div>
        <Documents :documents="selectedArchives" :textImage="$route.meta.group === 'exhibition' ? exhibition.doc_text_view : $store.state.indexDocsText"/>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Documents from '@/views/index/AllDocuments.vue'
import Subtitle from '@/components/Subtitle.vue'
import Footer from '@/components/Footer.vue'
import ScrollDirective from '@/mixins/DirectiveScroll'
import ScrollMixin from '@/mixins/MixinScroll'
import Vue from 'vue'

Vue.directive('current', ScrollDirective)

export default {
  props: ['documents'],

  mixins: [ScrollMixin],

  components: {
    Documents,
    Subtitle,
    Footer
  },

  computed: {
    exhibition () {
      return this.$store.getters.exhibition(this.$route.params.name)
    },

    selectedArchives () {
      if (this.$route.meta.group === 'exhibition') {
        let arr = this.exhibition.documents
        const unique = arr.map(e => e['archive_slug']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => arr[e]).map(e => {
          let docs = arr.filter(item => item.archive_slug === arr[e].archive_slug)

          let rObj = {
            archive: arr[e].archive,
            archive_image: arr[e].archive_image,
            archive_slug: arr[e].archive_slug,
            documents: docs
          }
          return rObj
        })

        return unique.filter(item => item.archive_slug === this.$route.params.archive)[0].documents.sort((a, b) => a.title.localeCompare(b.title))
      } else {
        if (this.archive) {
          return this.archive.documents
        } else {
          return null
        }
      }
    },

    archive () {
      return this.$store.getters.archive(this.$route.params.archive)
    }
  },

  watch: {
    archive: {
      immediate: true,
      handler (val) {
        if (val) this.$store.commit('editState', { state: 'arcTitle', data: this.archive.archive.text })
      }
    },

    currentSection (val) {
      // Commit current section to store
      this.$store.commit('editState', { state: 'arcSection', data: val })
    }
  }
}
</script>
