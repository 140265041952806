<template>
  <div id="exhibitionMenuOuter">
    <!-- About -->
    <GroupAbout />

    <!-- Index group -->
    <GroupIndex />

    <!-- Exhibition group -->
    <GroupExhibition />
  </div>
</template>

<script>
import GroupExhibition from '@/components/menu/GroupExhibition'
import GroupIndex from '@/components/menu/GroupIndex'
import GroupAbout from '@/components/menu/GroupAbout'
import MixinExhibitionMenu from '@/mixins/MixinExhibitionMenu.js'

export default {
  data () {
    return {
      scen: null
    }
  },

  mixins: [MixinExhibitionMenu],

  components: {
    GroupIndex,
    GroupAbout,
    GroupExhibition
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#exhibitionMenuOuter {
  z-index: 10;
  position: relative;
  height: 4rem;
  width: 100%;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    left: $padding;
    right: $padding;
    bottom: 0;
    border-bottom: $border;
  }

  .exhibitionMenu, #singleMenu, #singleMenuArc, .subMenu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $padding .3rem;

    span:not(.num) {
      height: 100%;
    }
  }

  .exMenOuter {
    height: 100%;
    position: relative;
    padding: 0 $menspac;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .exMenOuter:first-child {
    padding-left: 0;
  }

  .exMenOuter:last-child {
    padding-right: 0;
  }

  .exMenInner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }

  .selected:before {
    content: " ";
    position: absolute;
    left: -1.7rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100rem;
    background-color: black;
    border: $iconborder;
    margin-top: -.1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.docBack {
  text-transform: uppercase;
  font-size: $fonthorz;
  line-height: $horizontalLeading;
  font-family: $horizontal;
  position: absolute;
  left: $padding;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.arrow {
  font-family: Times;
}

#indexMen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: absolute;
  left: 0;
  transition: left 1s ease;

  a {
    color: white;
  }

  .flex {
    height: 100%;
    text-transform: uppercase;
    flex: 1;
    transition: transform 1s ease;
    will-change: transform;
  }

  .arcs {
    text-align: right;
    float: right;
    padding: 0 $padding;
    transition: transform 1s ease;
    display: flex;
  }

  .docs {
    text-align: right;
    float: left;
    padding: 0 $padding;
    transition: transform 1s ease;
  }

  .docsMobOuter {
    display: none;
  }

  .docs,
  .arcs,
  .docsMob {
    will-change: transform;
    height: 100%;
    font-family: $horizontal;
    line-height: $horizontalLeading;
    font-size: $fonthorz;
  }

  .inner {
    will-change: transform;
    height: 100%;
  }

  .inner a {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

#docsTextImage {
  position: absolute;
  left: -100%;
  padding: $padding;
}

#arcsTextImage {
  position: absolute;
  right: -100%;
  padding: $padding;
}

#indexMen.arcs {
  left: 0;

  .arcs {
    transform: translateX(50%);
  }
}

#indexMen.docs {
  left: 50%;

  .docs {
    transform: translateX(-50%);
  }
}

.docTitle {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  padding: 0 2rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: $breakPoint1) {
  #indexMen {
    width: 100%;
  }

  #exhibitionMenuOuter {
    .exhibitionMenu, #singleMenu, #singleMenuArc {
      overflow-x: scroll;
      height: 200%;
      padding-bottom: calc(4.2rem - var(--sb));
      justify-content: flex-start;
    }

    .exhibitionMenu {
      padding-left: calc(#{$menspac} * 2);
    }

    .exMenOuter {
      white-space: nowrap;
    }

    .exMenOuter:last-child {
      padding-right: $padding;
    }
  }

  .docTitle {
    display: none;
  }

  #indexMen {
    width: 150%;

    .docsTextImage,
    .arcsTextImage {
      display: none;
    }

    .docsMobOuter {
      display: block;
    }

    .docsMob {
      padding: 0 $padding;
      float: right;
      transition: transform 1s ease;
    }

    .arcs {
      text-align: center;
      float: none;
    }

    .inner {
      transition: margin 1s ease, transform 1s ease;
      will-change: transform;
      white-space: nowrap;
    }
  }

  #indexMen.arcs {
    left: -50%;

    .arcs {
      transform: translateX(-0%);

      .inner {
        margin-left: 0;
        transform: translateX(0%);
        position: relative;
      }
    }

    .docs .inner {
      margin-left: 25%;
      transform: translateX(-50%);
    }
  }

  #indexMen.docs {
    left: 0%;

    .arcs .inner {
      margin-left: 100%;
      transform: translateX(-100%);
    }

    .docs {
      transform: translateX(0%);
    }

    .docsMobOuter .inner {
      margin-left: 0;
      transform: translateX(25%);
    }
  }

  .docBack {
    white-space: nowrap;
    position: relative;
    left: 0;
    margin-right: calc(#{$menspac} * 2);
    margin-left: calc(#{$menspac} * -1);
  }
}

@media screen and (max-width: $breakPoint1) {
  #exhibitionMenuOuter .exMenOuter .textImSel {
    display: none;
  }
}
</style>
