<template>
  <div class="menu" v-if="exhibitions" ref="menu">
    <div class="menuInner" :class="{openEx: openEx}">
      <div class="top" :class="'top' + open">
        <MenuTop />
      </div>

      <div class="expand">
        <div class="expandInner">
          <Expo v-for="(exhibition, index) in exhibitions" :key="index + exhibition.slug" :menu="$refs.menu" :open="open" :openEx="openEx" :exhibition="exhibition" :index="index" />
          <div id="bottom" class="menuBorder">
            <div class="bottomBut">
              <div @click="openDocuments"><span v-html="$store.state.translations.documents[$store.state.lan]"></span><span class="num" v-if="documents" v-html="documents.length"></span></div>
            </div>

            <div class="bottomBut">
              <div @click="openArchives"><span v-if="$store.state.archives" v-html="$store.state.archives.title"></span> <span class="num" v-if="archives" v-html="archives.length"></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Expo from '@/components/menu/MenuExpo.vue'
import MenuTop from '@/components/menu/MenuTop.vue'

export default {
  data () {
    return {
      style: null,
      open: null,
      openEx: false
    }
  },

  components: {
    Expo,
    MenuTop
  },

  computed: {
    exhibitions () {
      return this.$store.getters.exhibitions
    },

    archives () {
      return this.$store.getters.archives
    },

    documents () {
      return this.$store.getters.documents
    }
  },

  methods: {
    openDocuments () {
      // Close menu
      this.$store.commit('editState', { state: 'hideContent', data: true })
      this.$router.push(`/${this.$store.state.lan}/index/documents`)
      this.$store.commit('editState', { state: 'mainMenu', data: false })
      window.setTimeout(() => {
        // Show content
        this.$store.commit('editState', { state: 'hideContent', data: false })
      }, 1100)
    },

    openArchives () {
      // Close menu
      this.$store.commit('editState', { state: 'hideContent', data: true })
      this.$router.push(`/${this.$store.state.lan}/index/archives`)
      this.$store.commit('editState', { state: 'mainMenu', data: false })
      window.setTimeout(() => {
        // Show content
        this.$store.commit('editState', { state: 'hideContent', data: false })
      }, 1100)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.menu {
  background-color: white;
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.menuInner {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: -$borderthick;
  transition: top 0.7s ease, bottom 0.7s ease;
  overflow-y: scroll;
}

.top {
  color: black;
  font-size: $fonthorz;
  line-height: $horizontalLeading;
  font-family: $horizontal;
  position: absolute;
  z-index: 2;
  height: 4rem;
  width: 100%;
  top: 0;
  transition: top 0.7s ease;
  padding: 0 $padding;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: $padding;
    right: $padding;
    border-bottom: $border;
  }
}

.menuBorder:after {
  content: " ";
  position: absolute;
  bottom: -$borderthick;
  left: $padding;
  right: $padding;
  border-bottom: $border;
}

.menuBorder:before {
  content: " ";
  position: absolute;
  top: 0;
  left: $padding;
  right: $padding;
  border-top: $border;
}

.expo {
  top: 0;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  font-family: $horizontal;
  line-height: $horizontalLeading;
  font-size: $fontmenu;
  text-align: center;
  height: calc(20% - 4rem / 5);
  transition: top 0.7s ease, transform 0.7s ease, height 0.7s ease;
}

.openEx {
  .top,
  .expo {
    will-change: transform;
  }

  .top0 {
    top: calc(-4rem - 6px);
  }

  .top1 {
    top: calc(((20% - 4rem / 5) * 2) * -1);
  }

  .top2 {
    top: calc(((20% - 4rem / 5) * 3) * -1);
  }

  .top3 {
    top: calc(((20% - 4rem / 5) * 4) * -1);
  }

  .top4 {
    top: calc(((20% - 4rem / 5) * 5) * -1);
  }

  .expand {
    overflow: visible;
  }
}

.dates {
  display: none;
}

.expo:hover {
  .dates {
    display: block;
  }

  .cur {
    display: none;
  }
}

.expand {
  position: absolute;
  left: 0;
  width: 100%;
  top: 4rem;
  bottom: 0;
  overflow: hidden;
  color: black;
}

.expandInner {
  height: calc(var(--vh) * 100 - 4rem + #{$borderthick});
  position: absolute;
  bottom: $borderthick;
  left: 0;
  width: 100%;
  flex: 1;
  width: 100%;

  .expoInner {
    padding: 1rem 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transition: top 0.2s ease, transform 0.2s ease;
    transform: translate(-50%, -50%);
  }

  .flexOpen {
    .expoInner {
      top: 0;
      transform: translate(-50%, 0%);
    }
  }

  #bottom {
    position: relative;
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $horizontal;
    line-height: $horizontalLeading;
    font-size: $fonthorz;

    .bottomBut {
      height: 100%;
      text-transform: uppercase;
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        padding: 0 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.othersMenu {
  height: 100%;
  display: flex;
}

@media (orientation: portrait) {
  .expo {
    font-size: $fontmenumob;
  }
}

@media screen and (max-width: $breakPoint1) {
  .expandInner #bottom .bottomBut:first-child {
    justify-content: flex-start;
    padding-left: 1rem;
  }

  .expandInner #bottom .bottomBut:last-child {
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .expo {
    font-size: $fontmenumob;
  }
}

@import '@/components/menu/menuAnimations.scss';
</style>
