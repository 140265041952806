import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'

Vue.use(Vuex)

const Axios = axios.create({
  baseURL: 'https://idea.matera-basilicata2019.it/cms/api/',
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
})

export default new Vuex.Store({
  state: {
    // Content
    home: null,
    about: null,
    exhibition: null,
    exhibitions: null,
    archives: null,
    translations: {
      documents: {
        it: 'Documenti',
        en: 'Documents'
      },
      archive: {
        it: 'Archivio',
        en: 'Archive'
      },
      exhibition: {
        it: 'Mostra',
        en: 'Exhibition'
      },
      text: {
        it: 'Titoli',
        en: 'Text'
      },
      image: {
        it: 'Immagini',
        en: 'Image'
      },
      details: {
        it: 'Dettagli',
        en: 'Details'
      },
      introduction: {
        it: 'Introduzione',
        en: 'Introduction'
      },
      selectedDocs: {
        it: 'Documenti selezionati',
        en: 'Selected Documents'
      },
      selectedArcs: {
        it: 'Archivi selezionati',
        en: 'Selected Archives'
      }
    },

    // Section animations and menus
    currSection: null,
    aboutSection: null,
    docSection: null,
    arcSection: null,

    // The rest
    dir: 'up',
    mainMenu: false,
    menuAnimation: 'menu',
    hideContent: false,
    scrollMenu: true,
    stopRequest: false,
    bodyEl: null,
    docTitle: null,
    arcTitle: null,
    docMen: false,
    collateralText: true,
    indexDocsText: true,
    indexArcsText: true,
    toIndex: null,
    fromIndex: null,
    remVal: 4,
    transitionName: 'index',
    transitionMain: 'none',
    backgroundColor: '#fff',
    mobAutoMenu: false,
    lan: null,
    internalNavigation: false,
    pageKey: null
  },

  getters: {
    // Content getters, return null if not set
    home: state => state.home ? state.home : null,

    archives: state => state.archives ? state.archives.archives : null,

    about: state => state.about ? state.about : null,

    exhibition: (state) => (slug) => {
      return state.exhibitions ? state.exhibitions.find(page => page.slug === slug) : null
    },

    documents: (state) => {
      let arr = state.archives ? state.archives.archives : null
      if (state.archives) {
        const unique = arr.reduce((all, e) => {
          e['documents'].forEach(doc => {
            all.push(doc)
          })

          return all
        }, [])
        return unique
      } else {
        return null
      }
    },

    document: (state) => (slug, doc) => {
      let expo = state.exhibitions ? state.exhibitions.find(page => page.slug === slug) : null
      return expo.documents ? expo.documents.find(page => page.slug === doc) : null
    },

    archive: (state) => (slug) => {
      return state.archives ? state.archives.archives.find(page => page.archive_slug === slug) : null
    },

    exhibitions: state => state.exhibitions ? state.exhibitions : null,

    // Other
    bodyEl: state => state.bodyEl
  },

  mutations: {
    // Generic state modifier
    editState (state, newState) {
      state[newState.state] = newState.data
    }
  },

  actions: {
    // Example axios fetch and commit
    async loadHome ({ commit, state }) {
      const result = await Axios.get('home', {
        params: {
          lan: state.lan
        }
      })

      commit('editState', { state: 'home', data: result.data })
    },

    async loadAbout ({ commit, state }) {
      const result = await Axios.get('about', {
        params: {
          lan: state.lan
        }
      })

      commit('editState', { state: 'about', data: result.data })
    },

    async loadExhibitions ({ commit, state }, url) {
      const result = await Axios.get('exhibitions', {
        params: {
          lan: state.lan
        }
      })

      commit('editState', { state: 'exhibitions', data: result.data.exhibitions })
    },

    async loadArchives ({ commit, state }, url) {
      const result = await Axios.get('archives', {
        params: {
          lan: state.lan
        }
      })

      commit('editState', { state: 'archives', data: result.data })
    }
  }
})
