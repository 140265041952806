<template>
  <div class="slideOuter" ref="item" v-show="show" :style="{zIndex: max - index}" :id="'slide' + index" :class="{first: index === 0, lastSlide: index === max - 2, done: last}">
    <div class="slideInner">
      <div v-if="slide.type === 'single'">
        <div class="singleImage" :style="style">
          <img :src="slide.image.src_low">
        </div>
      </div>

      <div v-else-if="slide.type === 'double'">
        <div class="leftImage" :class="{hide: inP < 50}">
          <img :src="slide.image_left.src_low">
        </div>
        <div class="singleImage" :style="style">
          <div class="rightImage" :class="{hide: inP < 0.05}">
            <img :src="slide.image_right.src_low">
          </div>
        </div>
      </div>

      <div v-else class="textSlide" :style="{opacity: opacity}" :class="{title: index === 0}">
        <span v-html="slide.text"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      style: null,
      inP: null,
      hide: false,
      show: false,
      opacity: 1,
      observer: null
    }
  },

  props: ['slide', 'index', 'max', 'last', 'reset'],

  watch: {
    reset (val) {
      this.observer = null
      this.startObserve()
    },

    '$mq' (val) {
      this.observer = null
      this.startObserve()
    }
  },

  methods: {
    handleIntersect (entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.hide = false
        } else {
          this.hide = true
        }

        let ratio = +((entry.intersectionRatio * 100).toFixed(2))
        let dRec = entry.target.getBoundingClientRect()
        let inP
        let inP2 = ratio

        if (this.$mq === 'sm') {
          inP = ((dRec.left + entry.rootBounds.width) / entry.rootBounds.width) * 100
        } else {
          inP = ((dRec.top + entry.rootBounds.height) / entry.rootBounds.height) * 100
        }

        if (inP > 100) inP = 100
        if (inP < 0) inP = 0

        if (inP2 > 100) inP2 = 100
        if (inP2 < 0) inP2 = 0

        const left = 100 - inP
        this.inP = inP
        this.opacity = inP / 100
        this.style = {
          left: '-' + left + '%'
        }

        if (this.$store.state.dir === 'down') {
          if (entry.isIntersecting && ratio > 50) this.$parent.current = this.index
        } else {
          if (entry.isIntersecting && ratio > 5) this.$parent.current = this.index
        }

        if (this.index === this.max - 1 && entry.intersectionRatio === 1) {
          this.$store.commit('editState', { state: 'mainMenu', data: true })
        }

        if (this.index === this.max - 1 && entry.intersectionRatio === 0) {
          this.$store.commit('editState', { state: 'mainMenu', data: false })
        }
      })
    },

    startObserve () {
      const options = {
        root: null,
        rootMargin: `0px 0px 0px 0px`,
        threshold: Array.apply(null, { length: 100 }).map((n, i) => i / 100)
      }

      this.observer = new IntersectionObserver(this.handleIntersect, options)
      this.observer.observe(this.$refs.item)
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.show = true

      if (this.max - 1 === this.index) {
        setTimeout(() => {
          this.$parent.last = true
        }, 500)
      }
    })

    this.startObserve()
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.slideOuter {
  position: relative;
  width: 100vw;
  height: calc(var(--vh) * 100);
  margin-bottom: calc(var(--vh) * 25);
  pointer-events: auto;
  opacity: 0;
  transition: opacity 0s ease 0.3s;
}

.slideOuter.first {
  transition: opacity 0.3s ease;
}

.done {
  opacity: 1;
}

.slideOuter:last-child {
  height: 1px;
  margin-right: 0;
  width: 1px;
  margin-bottom: 0;

  .slideInner {
    position: absolute;
  }
}

.hide {
  display: none;
}

.current .slideInner {
  will-change: transform;
}

.slideInner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .textSlide {
    padding: 0 2rem;
    pointer-events: none;
    font-family: $magister;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    font-size: $fontslide;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    text-align: center;
    opacity: 0;
  }

  .title {
    font-family: $horizontal;
    line-height: $horizontalLeading;
    text-transform: uppercase;
    font-size: $fonthuge;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }

  .singleImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .leftImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: white;
  }

  .rightImage {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: white;
  }

  img {
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.lastSlide .singleImage {
  left: 0 !important;
}

@media screen and (max-width: $breakPoint1) {
  .slideInner {
    .textSlide {
      font-size: 3rem;
    }

    .title {
      font-size: 6rem;
    }
  }

  .slideOuter {
    margin-bottom: 0;
    margin-right: 25rem;
  }
}
</style>
