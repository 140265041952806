<template>
  <div id="exhibition" class="exhibition" v-if="about" :class="[`to-${toIndex}`, `from-${fromIndex}`]">
    <ExhibitionMenus :colour="about.colour" />

    <div id="exhibitionContent" class="pagefade">
      <transition-group :name="$store.state.transitionName" @enter="enter" @after-enter="afterEnter">
        <Sections  id="sections" class="indexDocs addTop" v-if="toIndex === 'documents'" key="indexDocs" :exhibition="about" />
        <Collateral :about="about" v-if="toIndex === 'index-doc'" key="indexDoc" class="addTop" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import ExhibitionMenus from '@/components/menu/ExhibitionMenus.vue'
import Sections from '@/views/exhibition/AboutSections.vue'
import Collateral from '@/views/index/IndexCollateral.vue'
import MixinDocsAbout from '@/mixins/MixinDocsAbout.js'

export default {
  components: {
    ExhibitionMenus,
    Collateral,
    Sections
  },

  mixins: [MixinDocsAbout],

  computed: {
    about () {
      return this.$store.getters.about
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('editState', { state: 'backgroundColor', data: '#fff' })
    })
  }
}
</script>
