<template>
  <transition-group :name="$store.state.transitionName" v-if="$route.meta.group === 'exhibition'" class="pagefade">
    <!-- Expo menu -->
    <div class="exhibitionMenu indexMain" :id="toIndex === 'documents' ? 'exhibitionMenu' : ''" v-show="toIndex === 'documents'" :key="'indexMain' + this.$route.params.name">
      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'inro'}" class="exMenInner" @click="goTo('inro')" id="meninro">{{exhibition.header.title}}</div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'scen'}" class="exMenInner" @click="goTo('scen')" id="menscen">{{exhibition.scenario.title}}</div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'cura'}" class="exMenInner" @click="goTo('cura')" id="mencura">{{exhibition.curators.title}}</div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'inst'}" class="exMenInner" @click="goTo('inst')" id="meninst">{{exhibition.installation.title}}</div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'docs'}" class="exMenInner" @click="goTo('docs')" id="mendocs">{{$store.state.translations.selectedDocs[$store.state.lan]}}</div>
        <div class="textImSel" v-if="$store.state.currSection === 'docs'">: &nbsp;
          <div :class="{iselected: docView}" @click="exhibition.doc_text_view = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !docView}" @click="exhibition.doc_text_view = false">{{$store.state.translations.image[$store.state.lan]}}</div>
        </div>
      </div>

      <div class="exMenOuter fadeOut">
        <div :class="{selected: $store.state.currSection === 'arcs'}" class="exMenInner" @click="goTo('arcs')" id="menarcs">{{$store.state.translations.selectedArcs[$store.state.lan]}}</div>
        <div class="textImSel" v-if="$store.state.currSection === 'arcs'">: &nbsp;
          <div :class="{iselected: arcView}" @click="exhibition.arc_text_view = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !arcView}" @click="exhibition.arc_text_view = false">{{$store.state.translations.image[$store.state.lan]}}</div>
        </div>
      </div>
    </div>

    <!-- Document menu -->
    <div class="exhibitionMenu singleDoc" :id="toIndex === 'index-doc' || toIndex === 'index-arc-doc' ? 'exhibitionMenu' : ''" v-if="toIndex === 'index-doc' || toIndex === 'index-arc-doc'" key="indexDoc">
      <div class="docBack fadeOut" id="mendoin">
        <template v-if="$route.params.archive">
          <span @click="$router.push(`/${$store.state.lan}/exhibition/${$route.params.name}/archive/${$route.params.archive}`)">&larr;
            <transition name="docMenu">
              <span v-show="contextMenu">{{$store.state.translations.archive[$store.state.lan]}}</span>
            </transition>
          </span>
        </template>

        <template v-else>
          <span @click="$router.push(`/${$store.state.lan}/exhibition/${$route.params.name}`)">&larr;
            <transition name="docMenu">
              <span v-show="contextMenu">{{$store.state.translations.documents[$store.state.lan]}}</span>
            </transition>
          </span>
        </template>
      </div>

      <transition-group name="docMenu">
        <div key="title" class="docTitle" v-if="!$store.state.docMen">
          <div class="fadeOut" v-html="$store.state.docTitle"></div>
        </div>

        <div key="others" class="othersMenu" v-if="contextMenu">
          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'doin'}" class="exMenInner" @click="goTo('doin')">{{exhibition.header.title}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.docSection === 'dets'}" class="exMenInner" @click="goTo('dets')" id="mendets">{{$store.state.translations.details[$store.state.lan]}}</div>
          </div>
        </div>
      </transition-group>
    </div>

    <!-- Archive menu -->
    <div id="exhibitionMenu" class="exhibitionMenu singleArc" v-if="toIndex === 'index-arc'" key="indexArc">
      <div class="docBack fadeOut" id="mendoin">
        <span @click="$router.push(`/${$store.state.lan}/exhibition/${$route.params.name}`)">&larr;
          <transition name="docMenu">
            <span v-show="contextMenu">{{$store.state.translations.exhibition[$store.state.lan]}}</span>
          </transition>
        </span>
      </div>

      <transition-group name="docMenu">
        <div key="title" class="docTitle" v-if="!$store.state.docMen">
          <div class="fadeOut" v-html="$store.state.arcTitle"></div>
        </div>

        <div key="others" class="othersMenu" v-if="contextMenu">
          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'doin'}" class="exMenInner" @click="goTo('doin')">{{exhibition.header.title}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'dets'}" class="exMenInner" @click="goTo('dets')" id="mendets">{{$store.state.translations.details[$store.state.lan]}}</div>
          </div>

          <div class="exMenOuter fadeOut">
            <div :class="{selected: $store.state.arcSection === 'docs'}" class="exMenInner" @click="goTo('docs')" id="mendocs">{{$store.state.translations.selectedDocs[$store.state.lan]}}</div>
            <div class="textImSel" v-if="$store.state.arcSection === 'docs'">: &nbsp;
              <div :class="{iselected: docView}" @click="exhibition.doc_text_view = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !docView}" @click="exhibition.doc_text_view = false">{{$store.state.translations.image[$store.state.lan]}}</div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </transition-group>
</template>

<script>
import MixinExhibitionMenu from '@/mixins/MixinExhibitionMenu.js'

export default {
  computed: {
    archives () {
      return this.$store.getters.archives
    },

    exhibition () {
      return this.$store.getters.exhibition(this.$route.params.name)
    },

    docView () {
      return this.exhibition.doc_text_view
    },

    arcView () {
      return this.exhibition.arc_text_view
    }
  },

  mixins: [MixinExhibitionMenu]
}
</script>
