<template>
  <div id="exhibition" class="exhibition" v-if="exhibition" :class="[`to-${toIndex}`, `from-${fromIndex}`]">
    <Top :key="exhibition.title" :exhibition="exhibition" v-show="!hideTop"/>
    <ExhibitionMenus :colour="exhibition.colour" />

    <div id="exhibitionContent" class="pagefade">
      <transition-group :name="$store.state.transitionName" @enter="enter" @after-enter="afterEnter">
        <Sections  id="sections" class="indexDocs addTop" v-if="toIndex === 'documents'" key="indexDocs" :exhibition="exhibition"/>

        <Document v-if="toIndex === 'index-doc' || toIndex === 'index-arc-doc'" key="indexDoc" class="addTop"/>

        <Archive v-if="toIndex === 'index-arc'" key="indexArc" class="addTop" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import ExhibitionMenus from '@/components/menu/ExhibitionMenus.vue'
import Document from '@/views/index/IndexDocument.vue'
import Archive from '@/views/index/IndexArchive.vue'
import Sections from '@/views/exhibition/Sections.vue'
import Top from '@/views/exhibition/Top.vue'
import MixinDocsExhibition from '@/mixins/MixinDocsExhibition.js'

export default {
  components: {
    ExhibitionMenus,
    Document,
    Archive,
    Sections,
    Top
  },

  mixins: [MixinDocsExhibition],

  computed: {
    exhibition () {
      return this.$store.getters.exhibition(this.$route.params.name)
    }
  },

  watch: {
    exhibition: {
      immediate: true,
      handler (val) {
        if (val) this.$store.commit('editState', { state: 'backgroundColor', data: val.colour })
      }
    }
  }
}
</script>
