<template>
  <div id="index" class="exhibition" v-if="archives && documents" :class="[`to-${toIndex}`, `from-${fromIndex}`]">
    <ExhibitionMenus colour="#000" />

    <div class="fadeIndex pagefade">
      <transition-group :name="$store.state.transitionName" @enter="enter" @after-enter="afterEnter">
        <Document v-if="toIndex === 'index-doc' || toIndex === 'index-arc-doc'" key="indexDoc" class="addTop" />

        <Archive v-if="toIndex === 'index-arc'" key="indexArc" class="addTop" />

        <div id="documents" class="indexDocs addTop" v-else-if="toIndex === 'documents'" key="indexDocs">
          <div class="section">
            <Subtitle :content="$store.state.archives.documents_text" />
            <div class="border" v-bar></div>
          </div>

          <div class="imageTextContextual">
            <div class="fadeOut">
              <div class="textImSel">
                <div :class="{iselected: $store.state.indexDocsText}" @click="$store.state.indexDocsText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.indexDocsText}" @click="$store.state.indexDocsText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
              </div>
            </div>
          </div>

          <Documents :documents="documents" :textImage="$store.state.indexDocsText"/>

          <Footer />
        </div>

        <div id="archives" class="indexArcs addTop" v-else-if="toIndex === 'archives'" key="indexArcs">
          <div class="section">
            <Subtitle :content="$store.state.archives.archives_text" />
            <div class="border" v-bar></div>
          </div>

          <div class="imageTextContextual">
            <div class="fadeOut">
              <div class="textImSel">
                <div :class="{iselected: $store.state.indexArcsText}" @click="$store.state.indexArcsText = true">{{$store.state.translations.text[$store.state.lan]}},</div>&nbsp;<div :class="{iselected: !$store.state.indexArcsText}" @click="$store.state.indexArcsText = false">{{$store.state.translations.image[$store.state.lan]}}</div>
              </div>
            </div>
          </div>

          <Archives :archives="archives" :textImage="$store.state.indexArcsText" />

          <Footer />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Documents from '@/views/index/AllDocuments.vue'
import Document from '@/views/index/IndexDocument.vue'
import Archives from '@/views/index/AllArchives.vue'
import Archive from '@/views/index/IndexArchive.vue'
import Footer from '@/components/Footer.vue'
import Subtitle from '@/components/Subtitle.vue'
import ExhibitionMenus from '@/components/menu/ExhibitionMenus.vue'
import MixinDocsIndex from '@/mixins/MixinDocsIndex.js'

export default {
  components: {
    ExhibitionMenus,
    Document,
    Documents,
    Archives,
    Archive,
    Subtitle,
    Footer
  },

  mixins: [MixinDocsIndex],

  computed: {
    archives () {
      return this.$store.getters.archives
    },

    documents () {
      return this.$store.getters.documents
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('editState', { state: 'backgroundColor', data: '#000' })
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#documents,
#archives {
  padding: 0 $padding;
}
</style>
