<template>
  <div class="homeOuter">
    <div class="selLan">
      <div class="textImSel">
        <div :key="$store.state.lan" :class="{iselected: $store.state.lan === 'it'}" @click="changeLanguage('it')">It<span>,</span></div>&nbsp;<div :class="{iselected: $store.state.lan === 'en'}" @click="changeLanguage('en')">En</div>
      </div>
    </div>

    <div id="slideShow" v-if="content" :class="{moving: stopClick}" @click="nextSlide($event.pageX)">
      <transition name="menuButton">
        <MenuButton v-show="!$store.state.mainMenu"/>
      </transition>

      <Slide v-for="(slide, index) in images" :class="{current: index === current}" :ref="'slide' + index" :reset="reset" :last="last" :key="'slide' + index" :slide="slide" :index="index" :max="images.length"/>
    </div>
  </div>
</template>

<script>
import MixinLan from '@/mixins/MixinLan'
import VueScrollTo from 'vue-scrollto'
import Vue from 'vue'
import Slide from '@/components/Slide.vue'
import MenuButton from '@/components/menu/MenuButton.vue'

Vue.use(VueScrollTo)

export default {
  data () {
    return {
      current: 0,
      options: {
        container: '#app',
        duration: 1000,
        easing: 'ease-out',
        offset: -10,
        force: true,
        cancelable: false,
        onDone: () => {
          this.stopClick = false
          if (this.clicks.length) this.clickPromise()
        },
        onCancel: () => {
          this.stopClick = false
        },
        x: this.$mq === 'sm',
        y: this.$mq !== 'sm'
      },
      last: false,
      slideTime: null,
      images: null,
      desktopImages: null,
      mobileImages: null,
      reset: null,
      stopClick: null,
      clicks: []
    }
  },

  mixins: [MixinLan],

  components: {
    MenuButton,
    Slide
  },

  computed: {
    bodyEl () {
      return this.$store.state.bodyEl
    },

    content () {
      return this.$store.getters.home
    }
  },

  mounted () {
    if (this.$mq === 'sm') {
      this.options.x = true
      this.options.y = false
    } else {
      this.options.x = false
      this.options.y = true
    }
  },

  watch: {
    '$mq' (val) {
      this.current = 0
      this.bodyEl.scrollTo(0, 0)
      this.reset = Date.now()

      if (val === 'sm') {
        this.options.x = true
        this.options.y = false
        this.images = this.mobileImages
      } else {
        this.options.x = false
        this.options.y = true
        this.images = this.desktopImages
      }
    },

    '$store.state.lan' (val) {
      this.current = 0
      this.bodyEl.scrollTo(0, 0)
      this.reset = Date.now()
    },

    content: {
      immediate: true,
      handler (val) {
        if (val) {
          // Normal images
          let images = this.content.images.slice()
          let last = {
            type: 'last'
          }

          images.push(last)
          this.desktopImages = images
          this.images = images

          // Mobile images
          let mobSlides = []
          this.content.images.forEach(item => {
            if (item.type === 'text') mobSlides.push(item)
            if (item.type === 'single') mobSlides.push(item)
            if (item.type === 'double') {
              let imageL = {
                type: 'single',
                image: item.image_left
              }

              let imageR = {
                type: 'single',
                image: item.image_right
              }

              mobSlides.push(imageL)
              mobSlides.push(imageR)
            }
          })

          mobSlides.push(last)
          this.mobileImages = mobSlides
          if (this.$mq === 'sm') this.images = mobSlides
        }
      }
    },

    last (val) {
      if (val) {
        this.slideTime = setTimeout(() => {
          let div = document.getElementById('slide1')
          this.$scrollTo(div, 500, this.options)
        }, 1500)

        this.bodyEl.addEventListener('scroll', this.stopScroll)
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('editState', { state: 'backgroundColor', data: '#fff' })
    })
  },

  methods: {
    clickPromise () {
      this.clicks.forEach(item => {
        this.nextSlide(item)
        this.clicks.shift()
      })
    },

    nextSlide (pageX) {
      if (!this.stopClick) {
        this.stopClick = true

        if (pageX <= window.outerWidth / 2 && this.current !== 0) {
          this.slideLeft()
        } else {
          this.slideRight()
        }
      } else {
        if (this.clicks.length < 2) this.clicks.push(pageX)
      }
    },

    slideLeft () {
      let prevI = (this.current - 1) < 0 ? 0 : (this.current - 1)
      let next = 'slide' + prevI
      this.$scrollTo(this.$refs[next][0].$el, 1000, this.options)
    },

    slideRight () {
      let prevI = (this.current + 1) > (this.max - 1) ? (this.max - 1) : (this.current + 1)
      let next = 'slide' + prevI
      this.$scrollTo(this.$refs[next][0].$el, 1000, this.options)
    },

    stopScroll () {
      clearTimeout(this.slideTime)
      this.bodyEl.removeEventListener('scroll', this.stopScroll)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.homeOuter,
#slideShow {
  width: 100%;
  position: relative;
  overflow: auto;
}

.menuButton-enter-active {
  transition: opacity 0.3s ease 1s;
}

.menuButton-leave-active {
  transition: opacity 0.3s ease;
}

.menuButton-enter,
.menuButton-leave-to {
  opacity: 0;
}

.selLan {
  z-index: 99;
  position: fixed;
  color: white;
  top: .8rem;
  right: 2rem;
}

#slideShow {
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
  cursor: pointer;
  user-select: none;
}

.homeOuter {
  .textImSel {
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);

    div:before {
      border-color: white;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    }

    .iselected:before {
      background-color: white;
    }
  }
}

@media screen and (max-width: $breakPoint1) {
  .homeOuter,
  #slideShow {
    overflow: visible;
  }

  #slideShow {
    white-space: nowrap;
    width: auto;
    position: absolute;
    height: calc(var(--vh) * 100);
  }

  .slideOuter {
    display: inline-block;
  }

  #app.home {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
</style>
