export default {
  data () {
    return {
      arcsScr: 0,
      docsScr: 0,
      arcDocScr: 0,
      bodScr: 0
    }
  },

  computed: {
    bodyEl () {
      return this.$store.state.bodyEl
    },

    scrollMenu () {
      return this.$store.state.scrollMenu
    },

    toIndex () {
      return this.$store.state.toIndex
    },

    fromIndex () {
      return this.$store.state.fromIndex
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.setToIndex(to)

    if (from.meta.name === 'index-doc') {
      this.$store.commit('editState', { state: 'fromIndex', data: from.meta.name })
    } else {
      this.$store.commit('editState', { state: 'fromIndex', data: 'documents' })
    }
    next()
  },

  beforeRouteLeave (to, from, next) {
    if (to.meta.group !== from.meta.group) {
      this.$store.commit('editState', { state: 'toIndex', data: to.meta.group })
    }

    next()
  },

  methods: {
    rem4 () {
      return this.scrollMenu ? 0 : Math.round(window.outerWidth / 100 * this.$store.state.remVal)
    },

    remOffset () {
      return this.scrollMenu ? Math.round(window.outerWidth / 100 * (this.$store.state.remVal / 2)) : 0
    },

    setToIndex (to) {
      if (to.meta.name === 'index-doc') {
        this.$store.commit('editState', { state: 'toIndex', data: to.meta.name })
      } else {
        this.$store.commit('editState', { state: 'toIndex', data: 'documents' })
      }
    },

    enter () {
      if (this.$store.state.transitionName === 'index') {
        // Get current scroll position
        this.bodSrc = this.bodyEl.scrollTop

        // Get menu height
        let rem4 = this.rem4()
        // Get offset height if menu is open
        let remOffset = this.remOffset()

        // Stop request loop for show/hide menu
        this.$store.commit('editState', { state: 'stopRequest', data: true })

        this.$nextTick(() => {
          this.bodyEl.scrollTo(0, rem4)
          let dScr = this.bodSrc - rem4

          // If entering to documents from document
          if (this.toIndex === 'documents' && this.fromIndex === 'index-doc') {
            document.getElementById('singleDocument').style.top = `-${dScr}px`
            document.getElementById('sections').style.top = `-${this.docsScr}px`
          }

          // If entering to document from documents
          if (this.toIndex === 'index-doc' && this.fromIndex === 'documents') {
            this.$store.commit('editState', { state: 'docMen', data: false })
            this.docsScr = dScr
            document.getElementById('singleDocument').style.top = `-${remOffset}px`
            document.getElementById('sections').style.top = `-${dScr}px`
          }

          this.bodyEl.scrollTo(0, rem4 + 2)
          this.bodyEl.scrollTo(0, rem4)
        })
      }
    },

    afterEnter () {
      if (this.$store.state.transitionName === 'index') {
        let rem4 = this.rem4()
        let remOffset = this.remOffset()

        if (this.toIndex === 'documents') {
          this.bodyEl.scrollTo(0, this.docsScr + rem4)
          document.getElementById('sections').style.top = '0px'
          this.$nextTick(() => {
            this.bodyEl.scrollTo(0, this.docsScr + rem4 + 2)
            this.bodyEl.scrollTo(0, this.docsScr + rem4)
          })
        }

        if (this.toIndex === 'index-doc') {
          this.bodyEl.scrollTo(0, rem4 + remOffset)
          document.getElementById('singleDocument').style.top = '0px'
        }

        this.$nextTick(() => {
          this.$store.commit('editState', { state: 'stopRequest', data: false })
        })
      } else {
        this.bodyEl.scrollTo(0, 0)
      }
    }
  },

  mounted () {
    this.setToIndex(this.$route)
  }
}
