<template>
  <div id="menuButton" @click.self.stop="setMenu">
    MENU
  </div>
</template>

<script>
export default {
  methods: {
    setMenu () {
      this.$store.commit('editState', { state: 'menuAnimation', data: 'menu' })
      this.$store.commit('editState', { state: 'mainMenu', data: !this.$store.state.mainMenu })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#menuButton {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: $padding;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.3rem;
  height: 4rem;
  font-size: $fonthorz;
  line-height: $horizontalLeading;
  font-family: $horizontal;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
  color: white;
  cursor: pointer;
}
</style>
